import { addSpacesBetweenNumbers, onlyNumbers } from '@/utils/helpers';

type GetFinalValueProps = {
  value: string;
  formattedValue: string;
  isTel: boolean;
  isNumberWithSpaces?: boolean;
  isNumber?: boolean;
};
export const getFinalValue = ({
  value,
  formattedValue,
  isTel,
  isNumberWithSpaces,
  isNumber,
}: GetFinalValueProps) => {
  switch (true) {
    case isTel:
      return formattedValue;
    case isNumberWithSpaces:
      return addSpacesBetweenNumbers(value);
    case isNumber:
      return onlyNumbers(value);
    default:
      return value;
  }
};

export const getPlaceholder = (
  isFocused: boolean,
  label?: string,
  placeholder?: string
) => {
  return label ? (isFocused ? placeholder : '') : placeholder;
};
