import styled, { css } from 'styled-components';
import { ButtonBox } from '@/components/UI/Button/Button.styles';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { InputAppearance } from '@/components/UI/Input/Input.types';
import {
  INPUT_CLEAR_BUTTON_HEIGHT,
  INPUT_HEIGHT,
} from '@/components/UI/Input/Input.constants';
import { SearchIcon } from '@/components/UI/Icons/Icons';

function getInputStylesForAppearance(appearance?: InputAppearance) {
  switch (true) {
    case appearance === 'filledWithIcon': {
      return css`
        border: 0;
        padding-inline: 35px;
      `;
    }

    default: {
      return css``;
    }
  }
}

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputFilledBg = styled.div.attrs(() => ({
  'aria-label': 'filledWithIcon background',
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.secondary.background};
`;

export const SearchLeftIcon = styled(SearchIcon).attrs(() => ({
  'aria-label': 'search icon',
}))`
  position: absolute;
  top: calc(${INPUT_HEIGHT}px / 2 - (${INPUT_CLEAR_BUTTON_HEIGHT}px / 2));
  left: 10px;
`;

export const InputBox = styled.input<{
  $appearance?: InputAppearance;
  $withClear?: boolean;
  $hasBorderBottom?: boolean;
  $isBigText?: boolean;
}>`
  position: relative;
  z-index: 2;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid transparent;
  padding: ${({ $withClear, $isBigText }) =>
    $withClear ? '12px 20px 12px 0' : $isBigText ? '8px 0 0 0' : '12px 0'};
  outline: 0;
  font-size: ${({ $isBigText }) => ($isBigText ? '24px' : '15px')};
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: ${({ $isBigText }) => ($isBigText ? 500 : 400)};
  line-height: normal;
  color: ${({ theme }) => theme.palette.primary.labels};
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.palette.secondary.labels};
  }

  ${({ $hasBorderBottom }) =>
    $hasBorderBottom &&
    css`
      border-bottom-color: ${({ theme }) => theme.palette.primary.border};
    `}

  ${({ $appearance }) => getInputStylesForAppearance($appearance)}
`;

export const InputClear = styled(ButtonBox).attrs(() => ({
  type: 'button',
  $appearance: 'transparent',
  $shape: 'square',
  'aria-label': 'clear',
}))<{
  $hasLeftPadding?: boolean;
  $isYCentered?: boolean;
}>`
  position: absolute;
  top: calc(${INPUT_HEIGHT}px / 2 - (${INPUT_CLEAR_BUTTON_HEIGHT}px / 2));
  right: ${({ $hasLeftPadding }) => ($hasLeftPadding ? '10px' : '0')};
  z-index: 3;
  background-image: url('/images/close-filled.svg');
  height: ${INPUT_CLEAR_BUTTON_HEIGHT}px;
  width: ${INPUT_CLEAR_BUTTON_HEIGHT}px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  ${({ $isYCentered }) =>
    $isYCentered &&
    css`
      top: 50%;
      transform: translateY(-50%);
    `}
`;

export const InputLabel = styled(Typography.Title)<{
  $isShifted?: boolean;
}>`
  color: ${({ theme }) => theme.palette.secondary.labels};
  position: relative;
  top: ${({ $isShifted }) => ($isShifted ? '7.5px' : '21.5px')};
  left: 0;
  z-index: 1;
  transition: top 0.3s ease-in-out;
`;
