const getCategoriesMockAz = {
  data: {
    categories: [
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
        name: 'Nəqliyyat',
        path: 'elanlar/neqliyyat',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az avtomobil almaq və ya satmaq istəyənləri bir araya gətirir. Yeni və sürülmüş nəqliyyat vasitələrini, avtomobillər üçün ehtiyat hissə və aksesuarları əla qiymətlərlə alın və ya satın.',
        count: 86631,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mg',
            name: 'Avtomobillər',
            path: 'elanlar/neqliyyat/avtomobiller',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 8045,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
            name: 'Ehtiyat hissələri və aksesuarlar',
            path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
            count: 74081,
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NA',
            name: 'Su nəqliyyatı',
            path: 'elanlar/neqliyyat/su-neqliyyati',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 141,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NQ',
            name: 'Motosikletlər və mopedlər',
            path: 'elanlar/neqliyyat/motosikletler-mopedler',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 1119,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Ng',
            name: 'Tikinti texnikası',
            path: 'elanlar/neqliyyat/tikinti-texnikasi',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 744,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Nw',
            name: 'Aqrotexnika',
            path: 'elanlar/neqliyyat/aqrotexnika',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 624,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMw',
            name: 'Avtobuslar',
            path: 'elanlar/neqliyyat/avtobuslar',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 26,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNA',
            name: 'Yük maşınları və qoşqular',
            path: 'elanlar/neqliyyat/yuk-masinlari-ve-qosqular',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 771,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcxMQ',
            name: 'Qeydiyyat nişanları',
            path: 'elanlar/neqliyyat/qeydiyyat-nisanlari',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Nəqliyyat',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 1080,
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
        name: 'Elektronika',
        path: 'elanlar/elektronika',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az telefonlar və planşetlərdən çeşidli aksesuarlara qədər hər növ yeni və işlənmiş elektronika məhsulları üzrə çox sərfəli təkliflərin ünvanıdır – həm alıcılar, həm də satıcılar üçün sərfəli.',
        count: 80082,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMQ',
            name: 'Audio və video',
            path: 'elanlar/elektronika/audio-video',
            icon: null,
            __typename: 'Category',
            count: 5711,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMg',
            name: 'Kompüter aksesuarları',
            path: 'elanlar/elektronika/komputer-aksesuarlari',
            icon: null,
            __typename: 'Category',
            count: 5310,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMw',
            name: 'Oyunlar, pultlar və proqramlar',
            path: 'elanlar/elektronika/oyunlar-ve-programlar',
            icon: null,
            __typename: 'Category',
            count: 5781,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNA',
            name: 'Masaüstü kompüterlər',
            path: 'elanlar/elektronika/komputerler',
            icon: null,
            __typename: 'Category',
            count: 2273,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNQ',
            name: 'Komponentlər və monitorlar',
            path: 'elanlar/elektronika/komputer-avadanliqi',
            icon: null,
            __typename: 'Category',
            count: 7156,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNg',
            name: 'Planşet və elektron kitablar',
            path: 'elanlar/elektronika/plansetler',
            icon: null,
            __typename: 'Category',
            count: 1798,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNw',
            name: 'Noutbuklar və netbuklar',
            path: 'elanlar/elektronika/noutbuklar',
            icon: null,
            __typename: 'Category',
            count: 7843,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOA',
            name: 'Ofis avadanlığı və istehlak materialları',
            path: 'elanlar/elektronika/ofis-avadanliqi',
            icon: null,
            __typename: 'Category',
            count: 2618,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
            name: 'Telefonlar',
            path: 'elanlar/elektronika/telefonlar',
            icon: null,
            __typename: 'Category',
            count: 22800,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOQ',
            name: 'Nömrələr və SIM-kartlar',
            path: 'elanlar/elektronika/nomreler-ve-sim-kartlar',
            icon: null,
            __typename: 'Category',
            count: 3943,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMA',
            name: 'Fototexnika',
            path: 'elanlar/elektronika/fotoaparatlar-ve-linzalar',
            icon: null,
            __typename: 'Category',
            count: 3041,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MA',
            name: 'Smart saat və qolbaqlar',
            path: 'elanlar/elektronika/smart-saat-ve-qolbaqlar',
            icon: null,
            __typename: 'Category',
            count: 2786,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MQ',
            name: 'Televizorlar və aksesuarlar',
            path: 'elanlar/elektronika/televizor-ve-aksesuarlar',
            icon: null,
            __typename: 'Category',
            count: 4938,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNw',
            name: 'Şəbəkə və server avadanlığı',
            path: 'elanlar/elektronika/sebeke-avadanligi',
            icon: null,
            __typename: 'Category',
            count: 4084,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Elektronika',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
        name: 'Ehtiyat hissələri və aksesuarlar',
        path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az-da avtomobil ehtiyat hissələri və aksesuarlar çox sərfəli qiymətlərlə təklif olunur. Mağazaları və maşın "ölüxana"larını gəzməyi unudun.',
        count: 74081,
        propertyOptions: [
          {
            id: '7476',
            propertyId: '798',
            name: 'Videoqeydiyyatçılar',
            __typename: 'PropertyOption',
          },
          {
            id: '4050',
            propertyId: '798',
            name: 'Şinlər, disklər və təkərlər',
            __typename: 'PropertyOption',
          },
          {
            id: '4048',
            propertyId: '798',
            name: 'Siqnalizasiyalar',
            __typename: 'PropertyOption',
          },
          {
            id: '4045',
            propertyId: '798',
            name: 'Aksesuarlar',
            __typename: 'PropertyOption',
          },
          {
            id: '4046',
            propertyId: '798',
            name: 'Audio və video texnika',
            __typename: 'PropertyOption',
          },
          {
            id: '4044',
            propertyId: '798',
            name: 'Avto kosmetika və kimya',
            __typename: 'PropertyOption',
          },
          {
            id: '4051',
            propertyId: '798',
            name: 'Digər',
            __typename: 'PropertyOption',
          },
          {
            id: '7693',
            propertyId: '798',
            name: 'Diaqnostika cihazları',
            __typename: 'PropertyOption',
          },
          {
            id: '4047',
            propertyId: '798',
            name: 'Ehtiyat hissələri',
            __typename: 'PropertyOption',
          },
          {
            id: '4043',
            propertyId: '798',
            name: 'GPS-naviqatorlar',
            __typename: 'PropertyOption',
          },
          {
            id: '4119',
            propertyId: '798',
            name: 'Qeydiyyat nişanları',
            __typename: 'PropertyOption',
          },
        ],
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
          name: 'Nəqliyyat',
          count: 86631,
          path: 'elanlar/neqliyyat',
          icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
          __typename: 'Category',
        },
        children: [],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
        name: 'Ev və bağ üçün',
        path: 'elanlar/ev-ve-bag-ucun',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
        __typename: 'Category',
        metaDescription:
          'Təmir-tikinti üçün hər şey, mebel, yeni və islenmis ev esyalari, yeni və ikinci əl mətbəx ləvazimatları, məişət texnikası, bir sözlə, ev və bağ üçün əşyalar ilə bağlı hər şeyi burada tapa və sata bilərsiniz.',
        count: 72453,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OA',
            name: 'Təmir və tikinti',
            path: 'elanlar/ev-ve-bag-ucun/temir-tikinti',
            icon: null,
            __typename: 'Category',
            count: 13642,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OQ',
            name: 'Mebellər',
            path: 'elanlar/ev-ve-bag-ucun/mebel',
            icon: null,
            __typename: 'Category',
            count: 20568,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
            name: 'Məişət texnikası',
            path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
            icon: null,
            __typename: 'Category',
            count: 17356,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMw',
            name: 'Ərzaq',
            path: 'elanlar/ev-ve-bag-ucun/erzaq',
            icon: null,
            __typename: 'Category',
            count: 654,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MQ',
            name: 'Qab-qacaq və mətbəx ləvazimatları',
            path: 'elanlar/ev-ve-bag-ucun/qab-qacaq',
            icon: null,
            __typename: 'Category',
            count: 5706,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mg',
            name: 'Bitkilər',
            path: 'elanlar/ev-ve-bag-ucun/bitkiler',
            icon: null,
            __typename: 'Category',
            count: 1529,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mg',
            name: 'Xalçalar və aksesuarlar',
            path: 'elanlar/ev-ve-bag-ucun/xalcalar-aksesuarlar',
            icon: null,
            __typename: 'Category',
            count: 1526,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mw',
            name: 'Ev tekstili',
            path: 'elanlar/ev-ve-bag-ucun/ev-tekstili',
            icon: null,
            __typename: 'Category',
            count: 2332,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NA',
            name: 'Ev və bağ üçün işiqlandırma',
            path: 'elanlar/ev-ve-bag-ucun/ev-bag-ucun-ishiqlandirma',
            icon: null,
            __typename: 'Category',
            count: 2478,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NQ',
            name: 'Dekor və interyer',
            path: 'elanlar/ev-ve-bag-ucun/dekor-interyer',
            icon: null,
            __typename: 'Category',
            count: 3156,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Ng',
            name: 'Bağ və bostan',
            path: 'elanlar/ev-ve-bag-ucun/bag-bostan',
            icon: null,
            __typename: 'Category',
            count: 2643,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNQ',
            name: 'Ev təsərrüfatı malları',
            path: 'elanlar/ev-ve-bag-ucun/ev-teserrufati-mallari',
            icon: null,
            __typename: 'Category',
            count: 863,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Ev və bağ üçün',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
        name: 'Daşınmaz əmlak',
        path: 'elanlar/dasinmaz-emlak',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az ev alqı satqısı, eləcə də kiraye ev elanları yerləşdirmək və tapmaq üçün ideal məkandır. Burada hər növ daşınmaz əmlakı (mənzillər, evler, ofislər və s.) rahatlıqla ala və ya qısa vaxtda sata bilərsiniz.',
        count: 55753,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNQ',
            name: 'Mənzillər',
            path: 'elanlar/dasinmaz-emlak/menziller',
            icon: null,
            __typename: 'Category',
            count: 25399,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Daşınmaz əmlak',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMQ',
            name: 'Villalar, bağ evləri',
            path: 'elanlar/dasinmaz-emlak/baglar-ve-villalar',
            icon: null,
            __typename: 'Category',
            count: 18606,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Daşınmaz əmlak',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMg',
            name: 'Torpaq',
            path: 'elanlar/dasinmaz-emlak/torpaq-sahesi',
            icon: null,
            __typename: 'Category',
            count: 6881,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Daşınmaz əmlak',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMw',
            name: 'Qarajlar',
            path: 'elanlar/dasinmaz-emlak/qarajlar',
            icon: null,
            __typename: 'Category',
            count: 129,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Daşınmaz əmlak',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNA',
            name: 'Xaricdə əmlak',
            path: 'elanlar/dasinmaz-emlak/xaricde-emlak',
            icon: null,
            __typename: 'Category',
            count: 20,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Daşınmaz əmlak',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNQ',
            name: 'Obyektlər və ofislər',
            path: 'elanlar/dasinmaz-emlak/obyektler-ve-ofisler',
            icon: null,
            __typename: 'Category',
            count: 4718,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Daşınmaz əmlak',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
        name: 'Xidmətlər və biznes',
        path: 'elanlar/xidmetler',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az-da təqdim olunan çoxsaylı xidmətlərdən (elektrik, fotoqraf, repetitor və s.) yararlana və ya mütəxəssisi olduğunuz sahə üzrə xidmətlərinizi təklif edərək davamlı qazanc əldə edə bilərsiniz.',
        count: 32389,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OA',
            name: 'Avadanlığın icarəsi',
            path: 'elanlar/xidmetler/avadanliqin-icaresi',
            icon: null,
            __typename: 'Category',
            count: 432,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Nw',
            name: 'Avadanlıqların quraşdırılması',
            path: 'elanlar/xidmetler/avadanliqin-qurasdirilmasi',
            icon: null,
            __typename: 'Category',
            count: 195,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMQ',
            name: 'Biznes üçün avadanlıq',
            path: 'elanlar/xidmetler/biznes-avadaliqi',
            icon: null,
            __typename: 'Category',
            count: 17941,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MQ',
            name: 'Avtoservis və diaqnostika ',
            path: 'elanlar/xidmetler/avtoservis-ve-diaqnostika',
            icon: null,
            __typename: 'Category',
            count: 286,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mw',
            name: 'Dayələr, baxıcılar',
            path: 'elanlar/xidmetler/dayeler-baxicilar',
            icon: null,
            __typename: 'Category',
            count: 52,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NQ',
            name: 'Foto və video çəkiliş xidmətləri',
            path: 'elanlar/xidmetler/foto-ve-video-cekilis',
            icon: null,
            __typename: 'Category',
            count: 108,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NA',
            name: 'Gözəllik, sağlamlıq',
            path: 'elanlar/xidmetler/gozellik-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 89,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mg',
            name: 'Hüquq xidmətləri',
            path: 'elanlar/xidmetler/huquq-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 44,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mw',
            name: 'IT, internet, telekom',
            path: 'elanlar/xidmetler/komputer-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 413,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNg',
            name: 'Logistika',
            path: 'elanlar/xidmetler/logistika',
            icon: null,
            __typename: 'Category',
            count: 382,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OA',
            name: 'Mebel yığılması və təmiri',
            path: 'elanlar/xidmetler/sifarisle-mebel',
            icon: null,
            __typename: 'Category',
            count: 327,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mg',
            name: 'Musiqi, əyləncə və tədbirlər',
            path: 'elanlar/xidmetler/tedbirlerin-teskilati',
            icon: null,
            __typename: 'Category',
            count: 151,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OA',
            name: 'Mühasibat xidmətləri',
            path: 'elanlar/xidmetler/muhasibat-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 104,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OQ',
            name: 'Nəqliyyat vasitələrinin icarəsi',
            path: 'elanlar/xidmetler/neqliyyat-icaresi',
            icon: null,
            __typename: 'Category',
            count: 710,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Nw',
            name: 'Qidalanma, keyterinq',
            path: 'elanlar/xidmetler/keyterinq-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 106,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NQ',
            name: 'Reklam, dizayn və poliqrafiya',
            path: 'elanlar/xidmetler/reklam-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 661,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Ng',
            name: 'Sığorta xidmətləri',
            path: 'elanlar/xidmetler/sigorta-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 12,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MQ',
            name: 'Təhlükəsizlik sistemlərinin qurulması',
            path: 'elanlar/xidmetler/tehlukesizlik-sistemleri',
            icon: null,
            __typename: 'Category',
            count: 4804,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MA',
            name: 'Təlim, hazırlıq kursları',
            path: 'elanlar/xidmetler/telim-hazirliq-kurslari',
            icon: null,
            __typename: 'Category',
            count: 429,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
            name: 'Təmir və tikinti',
            path: 'elanlar/xidmetler/temir-tikinti',
            icon: null,
            __typename: 'Category',
            count: 3383,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MA',
            name: 'Təmizlik',
            path: 'elanlar/xidmetler/temizlik-xidmeti',
            icon: null,
            __typename: 'Category',
            count: 188,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NA',
            name: 'Tərcümə',
            path: 'elanlar/xidmetler/tercume-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 39,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Nw',
            name: 'Texnika təmiri',
            path: 'elanlar/xidmetler/texnika-temiri',
            icon: null,
            __typename: 'Category',
            count: 1045,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MQ',
            name: 'Tibbi xidmətlər',
            path: 'elanlar/xidmetler/tibbi-xidmetler',
            icon: null,
            __typename: 'Category',
            count: 138,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OQ',
            name: 'Digər',
            path: 'elanlar/xidmetler/diger-xidmetler',
            icon: null,
            __typename: 'Category',
            count: 350,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Xidmətlər və biznes',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
        name: 'Şəxsi əşyalar',
        path: 'elanlar/sexsi-esyalar',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az istifadəçiləri burada yeni və işlənmiş geyimlərini, aksesuarlarını və digər əşyalarını sataraq gəlir əldə edir, həmçinin itirdikləri və ya tapdıqları əşyalar barədə elanlar yerləşdirir.',
        count: 26966,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNw',
            name: 'Geyim və ayaqqabılar',
            path: 'elanlar/sexsi-esyalar/geyim-ayaqqabilar',
            icon: null,
            __typename: 'Category',
            count: 10594,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Şəxsi əşyalar',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNg',
            name: 'Saat və zinət əşyaları',
            path: 'elanlar/sexsi-esyalar/saatlar-zinet-esyalari',
            icon: null,
            __typename: 'Category',
            count: 5829,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Şəxsi əşyalar',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwOA',
            name: 'Aksesuarlar',
            path: 'elanlar/sexsi-esyalar/aksesuarlar',
            icon: null,
            __typename: 'Category',
            count: 3586,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Şəxsi əşyalar',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOA',
            name: 'Sağlamlıq və gözəllik',
            path: 'elanlar/sexsi-esyalar/gozellik-saglamliq',
            icon: null,
            __typename: 'Category',
            count: 6077,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Şəxsi əşyalar',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0Mw',
            name: 'İtmiş əşyalar',
            path: 'elanlar/sexsi-esyalar/itmis-esyalar',
            icon: null,
            __typename: 'Category',
            count: 34,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Şəxsi əşyalar',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OA',
            name: 'Elektron siqaretlər və tütün qızdırıcıları',
            path: 'elanlar/sexsi-esyalar/elektron-siqaretler',
            icon: null,
            __typename: 'Category',
            count: 846,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Şəxsi əşyalar',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
        name: 'Hobbi və asudə',
        path: 'elanlar/hobbi-ve-asude',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
        __typename: 'Category',
        metaDescription:
          'Asudə vaxtınızda məşğul olmaq üçün maraqlı hobbi axtarırsınız? Yaxın həftələrdə səyahətə çıxmaq istəyirsiniz? Bəs ürəyinizə yatan bir insanla tanış olmaq necə? Elədirsə, bura tam sizlikdir.',
        count: 20386,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMg',
            name: 'Biletlər və səyahət',
            path: 'elanlar/hobbi-ve-asude/turlar-ve-biletler',
            icon: null,
            __typename: 'Category',
            count: 155,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMw',
            name: 'Velosipedlər',
            path: 'elanlar/hobbi-ve-asude/velosipedler',
            icon: null,
            __typename: 'Category',
            count: 4006,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNA',
            name: 'Kolleksiyalar',
            path: 'elanlar/hobbi-ve-asude/kolleksiyalar',
            icon: null,
            __typename: 'Category',
            count: 2516,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNQ',
            name: 'Musiqi alətləri',
            path: 'elanlar/hobbi-ve-asude/musiqi-aletleri',
            icon: null,
            __typename: 'Category',
            count: 4015,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNg',
            name: 'İdman və asudə',
            path: 'elanlar/hobbi-ve-asude/idman-ve-asude',
            icon: null,
            __typename: 'Category',
            count: 6486,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNw',
            name: 'Kitab və jurnallar',
            path: 'elanlar/hobbi-ve-asude/kitab-ve-jurnallar',
            icon: null,
            __typename: 'Category',
            count: 1774,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOA',
            name: 'Kempinq, ovçuluq və balıqçılıq',
            path: 'elanlar/hobbi-ve-asude/kempinq-ovculuq-baliqciliq',
            icon: null,
            __typename: 'Category',
            count: 1161,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNw',
            name: 'Tanışlıq',
            path: 'elanlar/hobbi-ve-asude/tanisliq',
            icon: null,
            __typename: 'Category',
            count: 273,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Hobbi və asudə',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
        name: 'Uşaq aləmi',
        path: 'elanlar/usaqlar-ucun',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az-da hər yaşda uşaqlar üçün axtardığınız əşyaları (oyuncaqlar, geyim, usaq belekleri və s.) tapa və ya sata bilərsiniz. Məhsullar olduqca sərfəli qiymətə təqdim olunur və qısa müddətdə alıcı tapır.',
        count: 12082,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OQ',
            name: 'Avtomobil oturacaqları',
            path: 'elanlar/usaqlar-ucun/avtomobil-oturacaqlari',
            icon: null,
            __typename: 'Category',
            count: 229,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MA',
            name: 'Oyuncaqlar',
            path: 'elanlar/usaqlar-ucun/oyuncaqlar',
            icon: null,
            __typename: 'Category',
            count: 2333,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MQ',
            name: 'Uşaq arabaları',
            path: 'elanlar/usaqlar-ucun/usaq-arabalari',
            icon: null,
            __typename: 'Category',
            count: 1677,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Nw',
            name: 'Uşaq avtomobilləri',
            path: 'elanlar/usaqlar-ucun/usaq-avtomobilleri',
            icon: null,
            __typename: 'Category',
            count: 872,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mw',
            name: 'Uşaq çarpayıları və beşiklər',
            path: 'elanlar/usaqlar-ucun/usaq-carpayilari-ve-beshikler',
            icon: null,
            __typename: 'Category',
            count: 1625,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Ng',
            name: 'Uşaq daşıyıcıları',
            path: 'elanlar/usaqlar-ucun/usaq-dasiyicilari',
            icon: null,
            __typename: 'Category',
            count: 176,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mg',
            name: 'Uşaq geyimi',
            path: 'elanlar/usaqlar-ucun/usaq-geyimleri',
            icon: null,
            __typename: 'Category',
            count: 1904,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mw',
            name: 'Uşaq mebeli',
            path: 'elanlar/usaqlar-ucun/mebel',
            icon: null,
            __typename: 'Category',
            count: 457,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NA',
            name: 'Uşaq qidası və bəslənməsi',
            path: 'elanlar/usaqlar-ucun/usaq-yemekleri',
            icon: null,
            __typename: 'Category',
            count: 137,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NQ',
            name: 'Uşaq sürüşkənləri və oyun meydançaları',
            path: 'elanlar/usaqlar-ucun/usaq-suruskenleri-ve-oyun-meydancalari',
            icon: null,
            __typename: 'Category',
            count: 227,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mg',
            name: 'Manejlər',
            path: 'elanlar/usaqlar-ucun/manejler',
            icon: null,
            __typename: 'Category',
            count: 138,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OQ',
            name: 'Məktəblilər üçün',
            path: 'elanlar/usaqlar-ucun/mekteb-levazimatlari',
            icon: null,
            __typename: 'Category',
            count: 1288,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MA',
            name: 'Yürütəclər',
            path: 'elanlar/usaqlar-ucun/yurutecler',
            icon: null,
            __typename: 'Category',
            count: 264,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOA',
            name: 'Hamam və gigiyena',
            path: 'elanlar/usaqlar-ucun/usaq-gigiyenasi',
            icon: null,
            __typename: 'Category',
            count: 159,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOQ',
            name: 'Uşaq tekstili',
            path: 'elanlar/usaqlar-ucun/usaq-tekstili',
            icon: null,
            __typename: 'Category',
            count: 107,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NA',
            name: 'Qidalanma üçün uşaq oturacaqları',
            path: 'elanlar/usaqlar-ucun/qidalanma-ucun-usaq-oturacaqlar',
            icon: null,
            __typename: 'Category',
            count: 402,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NQ',
            name: 'Digər',
            path: 'elanlar/usaqlar-ucun/her-sey',
            icon: null,
            __typename: 'Category',
            count: 87,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Uşaq aləmi',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
        name: 'Heyvanlar',
        path: 'elanlar/heyvanlar',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az müxtəlif heyvanlar üçün yeni sahib tapmaqda və ya onlara sahiblənməkdə sizə yardımçı olacaq. Burada axtardığınız heyvan növlərini, onlar üçün müxtəlif qida, əşya və oyuncaqları tapa bilərsiniz.',
        count: 10473,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NA',
            name: 'İtlər',
            path: 'elanlar/heyvanlar/itler',
            icon: null,
            __typename: 'Category',
            count: 2081,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NQ',
            name: 'Pişiklər',
            path: 'elanlar/heyvanlar/pisikler',
            icon: null,
            __typename: 'Category',
            count: 1438,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Ng',
            name: 'Quşlar',
            path: 'elanlar/heyvanlar/quslar',
            icon: null,
            __typename: 'Category',
            count: 2578,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Nw',
            name: 'Akvariumlar və balıqlar',
            path: 'elanlar/heyvanlar/baliqlar-akvariumlar',
            icon: null,
            __typename: 'Category',
            count: 1443,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OQ',
            name: 'K/t heyvanları',
            path: 'elanlar/heyvanlar/kt-heyvanlari',
            icon: null,
            __typename: 'Category',
            count: 565,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMA',
            name: 'Heyvanlar üçün məhsullar',
            path: 'elanlar/heyvanlar/heyvanlar-ucun-mehsullar',
            icon: null,
            __typename: 'Category',
            count: 1975,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OA',
            name: 'Digər heyvanlar',
            path: 'elanlar/heyvanlar/diger-heyvanlar',
            icon: null,
            __typename: 'Category',
            count: 82,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMA',
            name: 'Dovşanlar',
            path: 'elanlar/heyvanlar/dovsanlar',
            icon: null,
            __typename: 'Category',
            count: 161,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMQ',
            name: 'Atlar',
            path: 'elanlar/heyvanlar/atlar',
            icon: null,
            __typename: 'Category',
            count: 95,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMg',
            name: 'Gəmiricilər',
            path: 'elanlar/heyvanlar/gemiriciler',
            icon: null,
            __typename: 'Category',
            count: 55,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Heyvanlar',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
        name: 'İş elanları',
        path: 'elanlar/is-elanlari',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
        __typename: 'Category',
        metaDescription:
          'Tap.az hər zaman olduğu kimi iş var və ya is axtariram deyənlərin işini asanlaşdırır. Yeni is elanlari 2022 mövsümü üzrə daim yenilənir, yüzlərlə şəxs isə qısa zamanda axtardığı vakansiyalar üzrə iş tapır.',
        count: 8488,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Bütün kateqoriyalar',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OA',
            name: 'Vakansiyalar',
            path: 'elanlar/is-elanlari/vakansiyalar',
            icon: null,
            __typename: 'Category',
            count: 6282,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
              name: 'İş elanları',
              count: 8488,
              path: 'elanlar/is-elanlari',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OQ',
            name: 'İş axtarıram',
            path: 'elanlar/is-elanlari/is-axtariram',
            icon: null,
            __typename: 'Category',
            count: 2206,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
              name: 'İş elanları',
              count: 8488,
              path: 'elanlar/is-elanlari',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Bütün kateqoriyalar',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
    ],
  },
};

const getCategoriesMockRu = {
  data: {
    categories: [
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
        name: 'Транспорт',
        path: 'elanlar/neqliyyat',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
        __typename: 'Category',
        metaDescription:
          'Автомобили, автозапчасти, аксессуары, автобусы и техника, мотоциклы, мопеды, водный транспорт, агротехника ',
        count: 86631,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mg',
            name: 'Легковые автомобили',
            path: 'elanlar/neqliyyat/avtomobiller',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 8045,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
            name: 'Автозапчасти и аксессуары',
            path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
            count: 74081,
            icon: 'https://tap.azstatic.com/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NA',
            name: 'Водный транспорт',
            path: 'elanlar/neqliyyat/su-neqliyyati',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 141,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NQ',
            name: 'Мотоциклы и мопеды',
            path: 'elanlar/neqliyyat/motosikletler-mopedler',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 1119,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Ng',
            name: 'Строительная техника',
            path: 'elanlar/neqliyyat/tikinti-texnikasi',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 744,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Nw',
            name: 'Агротехника',
            path: 'elanlar/neqliyyat/aqrotexnika',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 624,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMw',
            name: 'Автобусы',
            path: 'elanlar/neqliyyat/avtobuslar',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 26,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNA',
            name: 'Грузовики и прицепы',
            path: 'elanlar/neqliyyat/yuk-masinlari-ve-qosqular',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 771,
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcxMQ',
            name: 'Регистрационные номера',
            path: 'elanlar/neqliyyat/qeydiyyat-nisanlari',
            icon: null,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
              name: 'Транспорт',
              count: 86631,
              path: 'elanlar/neqliyyat',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
                count: 405703,
              },
            },
            __typename: 'Category',
            count: 1080,
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
        name: 'Электроника',
        path: 'elanlar/elektronika',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
        __typename: 'Category',
        metaDescription:
          'Аудио и видео, компьютерные аксессуары, телефоны, ноутбуки, нетбуки, настольные компьютеры, фототехника, планшеты, электронные книги, комплектующие, мониторы, игры, приставки, программы, номера и SIM-карты, оргтехника и расходники',
        count: 80082,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMQ',
            name: 'Аудио и видео',
            path: 'elanlar/elektronika/audio-video',
            icon: null,
            __typename: 'Category',
            count: 5711,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMg',
            name: 'Компьютерные аксессуары',
            path: 'elanlar/elektronika/komputer-aksesuarlari',
            icon: null,
            __typename: 'Category',
            count: 5310,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMw',
            name: 'Игры, приставки и программы',
            path: 'elanlar/elektronika/oyunlar-ve-programlar',
            icon: null,
            __typename: 'Category',
            count: 5781,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNA',
            name: 'Настольные компьютеры',
            path: 'elanlar/elektronika/komputerler',
            icon: null,
            __typename: 'Category',
            count: 2273,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNQ',
            name: 'Комплектующие и мониторы',
            path: 'elanlar/elektronika/komputer-avadanliqi',
            icon: null,
            __typename: 'Category',
            count: 7156,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNg',
            name: 'Планшеты и электронные книги',
            path: 'elanlar/elektronika/plansetler',
            icon: null,
            __typename: 'Category',
            count: 1798,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNw',
            name: 'Ноутбуки и нетбуки',
            path: 'elanlar/elektronika/noutbuklar',
            icon: null,
            __typename: 'Category',
            count: 7843,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOA',
            name: 'Оргтехника и расходники',
            path: 'elanlar/elektronika/ofis-avadanliqi',
            icon: null,
            __typename: 'Category',
            count: 2618,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
            name: 'Мобильные телефоны',
            path: 'elanlar/elektronika/telefonlar',
            icon: null,
            __typename: 'Category',
            count: 22800,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOQ',
            name: 'Номера и SIM-карты',
            path: 'elanlar/elektronika/nomreler-ve-sim-kartlar',
            icon: null,
            __typename: 'Category',
            count: 3943,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMA',
            name: 'Фототехника',
            path: 'elanlar/elektronika/fotoaparatlar-ve-linzalar',
            icon: null,
            __typename: 'Category',
            count: 3041,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MA',
            name: 'Смарт часы и браслеты',
            path: 'elanlar/elektronika/smart-saat-ve-qolbaqlar',
            icon: null,
            __typename: 'Category',
            count: 2786,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MQ',
            name: 'Телевизоры и аксессуары',
            path: 'elanlar/elektronika/televizor-ve-aksesuarlar',
            icon: null,
            __typename: 'Category',
            count: 4938,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNw',
            name: 'Сетевое и серверное оборудование',
            path: 'elanlar/elektronika/sebeke-avadanligi',
            icon: null,
            __typename: 'Category',
            count: 4084,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
              name: 'Электроника',
              count: 80082,
              path: 'elanlar/elektronika',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
        name: 'Автозапчасти и аксессуары',
        path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
        __typename: 'Category',
        metaDescription:
          'Продажа аксессуаров и запчастей для автомобилей – Регистрационные номера, GPS-навигаторы.',
        count: 74081,
        propertyOptions: [
          {
            id: '7476',
            propertyId: '798',
            name: 'Видеорегистраторы',
            __typename: 'PropertyOption',
          },
          {
            id: '4050',
            propertyId: '798',
            name: 'Шины, диски и колёса',
            __typename: 'PropertyOption',
          },
          {
            id: '4048',
            propertyId: '798',
            name: 'Противоугонные устройства',
            __typename: 'PropertyOption',
          },
          {
            id: '4045',
            propertyId: '798',
            name: 'Аксессуары',
            __typename: 'PropertyOption',
          },
          {
            id: '4046',
            propertyId: '798',
            name: 'Аудио и видеотехника',
            __typename: 'PropertyOption',
          },
          {
            id: '4044',
            propertyId: '798',
            name: 'Автокосметика и автохимия',
            __typename: 'PropertyOption',
          },
          {
            id: '4051',
            propertyId: '798',
            name: 'Другое',
            __typename: 'PropertyOption',
          },
          {
            id: '7693',
            propertyId: '798',
            name: 'Диагностические приборы',
            __typename: 'PropertyOption',
          },
          {
            id: '4047',
            propertyId: '798',
            name: 'Запчасти',
            __typename: 'PropertyOption',
          },
          {
            id: '4043',
            propertyId: '798',
            name: 'GPS-навигаторы',
            __typename: 'PropertyOption',
          },
          {
            id: '4119',
            propertyId: '798',
            name: 'Регистрационные номера',
            __typename: 'PropertyOption',
          },
        ],
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
          name: 'Транспорт',
          count: 86631,
          path: 'elanlar/neqliyyat',
          icon: 'https://tap.azstatic.com/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
          __typename: 'Category',
        },
        children: [],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
        name: 'Для дома и дачи',
        path: 'elanlar/ev-ve-bag-ucun',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
        __typename: 'Category',
        metaDescription:
          'Ремонт и строительство, мебель и интерьер, бытовая техника, посуда и товары для кухни, растения',
        count: 72453,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OA',
            name: 'Ремонт и строительство',
            path: 'elanlar/ev-ve-bag-ucun/temir-tikinti',
            icon: null,
            __typename: 'Category',
            count: 13642,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OQ',
            name: 'Мебель',
            path: 'elanlar/ev-ve-bag-ucun/mebel',
            icon: null,
            __typename: 'Category',
            count: 20568,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
            name: 'Бытовая техника',
            path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
            icon: null,
            __typename: 'Category',
            count: 17356,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMw',
            name: 'Продукты питания',
            path: 'elanlar/ev-ve-bag-ucun/erzaq',
            icon: null,
            __typename: 'Category',
            count: 654,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MQ',
            name: 'Посуда и товары для кухни',
            path: 'elanlar/ev-ve-bag-ucun/qab-qacaq',
            icon: null,
            __typename: 'Category',
            count: 5706,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mg',
            name: 'Растения',
            path: 'elanlar/ev-ve-bag-ucun/bitkiler',
            icon: null,
            __typename: 'Category',
            count: 1529,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mg',
            name: 'Ковры и аксессуары',
            path: 'elanlar/ev-ve-bag-ucun/xalcalar-aksesuarlar',
            icon: null,
            __typename: 'Category',
            count: 1526,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mw',
            name: 'Домашний текстиль',
            path: 'elanlar/ev-ve-bag-ucun/ev-tekstili',
            icon: null,
            __typename: 'Category',
            count: 2332,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NA',
            name: 'Освещение для дома и дачи',
            path: 'elanlar/ev-ve-bag-ucun/ev-bag-ucun-ishiqlandirma',
            icon: null,
            __typename: 'Category',
            count: 2478,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NQ',
            name: 'Декор и интерьер',
            path: 'elanlar/ev-ve-bag-ucun/dekor-interyer',
            icon: null,
            __typename: 'Category',
            count: 3156,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Ng',
            name: 'Дача и сад',
            path: 'elanlar/ev-ve-bag-ucun/bag-bostan',
            icon: null,
            __typename: 'Category',
            count: 2643,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNQ',
            name: 'Хозяйственные товары',
            path: 'elanlar/ev-ve-bag-ucun/ev-teserrufati-mallari',
            icon: null,
            __typename: 'Category',
            count: 863,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
              name: 'Для дома и дачи',
              count: 72453,
              path: 'elanlar/ev-ve-bag-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
        name: 'Недвижимость',
        path: 'elanlar/dasinmaz-emlak',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
        __typename: 'Category',
        metaDescription:
          'Квартиры, дома, дачи, коттеджи, земельные участки, объекты, офисы, гаражи, недвижимость за рубежом',
        count: 55753,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNQ',
            name: 'Квартиры',
            path: 'elanlar/dasinmaz-emlak/menziller',
            icon: null,
            __typename: 'Category',
            count: 25399,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Недвижимость',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMQ',
            name: 'Дома, дачи, коттеджи',
            path: 'elanlar/dasinmaz-emlak/baglar-ve-villalar',
            icon: null,
            __typename: 'Category',
            count: 18606,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Недвижимость',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMg',
            name: 'Земельные участки',
            path: 'elanlar/dasinmaz-emlak/torpaq-sahesi',
            icon: null,
            __typename: 'Category',
            count: 6881,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Недвижимость',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMw',
            name: 'Гаражи',
            path: 'elanlar/dasinmaz-emlak/qarajlar',
            icon: null,
            __typename: 'Category',
            count: 129,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Недвижимость',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNA',
            name: 'Недвижимость за рубежом',
            path: 'elanlar/dasinmaz-emlak/xaricde-emlak',
            icon: null,
            __typename: 'Category',
            count: 20,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Недвижимость',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNQ',
            name: 'Объекты и офисы',
            path: 'elanlar/dasinmaz-emlak/obyektler-ve-ofisler',
            icon: null,
            __typename: 'Category',
            count: 4718,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
              name: 'Недвижимость',
              count: 55753,
              path: 'elanlar/dasinmaz-emlak',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
        name: 'Услуги и бизнес',
        path: 'elanlar/xidmetler',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
        __typename: 'Category',
        metaDescription:
          'Аренда и установка оборудования, ремонт и строительство, установка охранных систем, реклама, дизайн и полиграфия, ремонт техники, сборка и реставрация мебели, перевод, обучение, подготовительные курсы, IT, интернет, музыка, развлечения и мероприятия, пита',
        count: 32389,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OA',
            name: 'Аренда оборудования',
            path: 'elanlar/xidmetler/avadanliqin-icaresi',
            icon: null,
            __typename: 'Category',
            count: 432,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Nw',
            name: 'Установка оборудования',
            path: 'elanlar/xidmetler/avadanliqin-qurasdirilmasi',
            icon: null,
            __typename: 'Category',
            count: 195,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMQ',
            name: 'Оборудование для бизнеса',
            path: 'elanlar/xidmetler/biznes-avadaliqi',
            icon: null,
            __typename: 'Category',
            count: 17941,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MQ',
            name: 'Автосервис и диагностика',
            path: 'elanlar/xidmetler/avtoservis-ve-diaqnostika',
            icon: null,
            __typename: 'Category',
            count: 286,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mw',
            name: 'Няни, сиделки',
            path: 'elanlar/xidmetler/dayeler-baxicilar',
            icon: null,
            __typename: 'Category',
            count: 52,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NQ',
            name: 'Фото и видеосъёмка',
            path: 'elanlar/xidmetler/foto-ve-video-cekilis',
            icon: null,
            __typename: 'Category',
            count: 108,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NA',
            name: 'Красота, здоровье',
            path: 'elanlar/xidmetler/gozellik-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 89,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mg',
            name: 'Юридические услуги',
            path: 'elanlar/xidmetler/huquq-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 44,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mw',
            name: 'IT, интернет, телеком',
            path: 'elanlar/xidmetler/komputer-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 413,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNg',
            name: 'Логистика',
            path: 'elanlar/xidmetler/logistika',
            icon: null,
            __typename: 'Category',
            count: 382,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OA',
            name: 'Сборка и реставрация мебели',
            path: 'elanlar/xidmetler/sifarisle-mebel',
            icon: null,
            __typename: 'Category',
            count: 327,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mg',
            name: 'Музыка, развлечения и мероприятия',
            path: 'elanlar/xidmetler/tedbirlerin-teskilati',
            icon: null,
            __typename: 'Category',
            count: 151,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OA',
            name: 'Бухгалтерские услуги',
            path: 'elanlar/xidmetler/muhasibat-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 104,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OQ',
            name: 'Аренда транспортных средств',
            path: 'elanlar/xidmetler/neqliyyat-icaresi',
            icon: null,
            __typename: 'Category',
            count: 710,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Nw',
            name: 'Питание, кейтеринг',
            path: 'elanlar/xidmetler/keyterinq-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 106,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NQ',
            name: 'Реклама, дизайн и полиграфия',
            path: 'elanlar/xidmetler/reklam-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 661,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Ng',
            name: 'Страхование',
            path: 'elanlar/xidmetler/sigorta-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 12,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MQ',
            name: 'Установка систем безопасности',
            path: 'elanlar/xidmetler/tehlukesizlik-sistemleri',
            icon: null,
            __typename: 'Category',
            count: 4804,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MA',
            name: 'Обучение, подготовительные курсы',
            path: 'elanlar/xidmetler/telim-hazirliq-kurslari',
            icon: null,
            __typename: 'Category',
            count: 429,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
            name: 'Ремонт и строительство',
            path: 'elanlar/xidmetler/temir-tikinti',
            icon: null,
            __typename: 'Category',
            count: 3383,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MA',
            name: 'Уборка',
            path: 'elanlar/xidmetler/temizlik-xidmeti',
            icon: null,
            __typename: 'Category',
            count: 188,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NA',
            name: 'Перевод',
            path: 'elanlar/xidmetler/tercume-xidmetleri',
            icon: null,
            __typename: 'Category',
            count: 39,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Nw',
            name: 'Ремонт техники',
            path: 'elanlar/xidmetler/texnika-temiri',
            icon: null,
            __typename: 'Category',
            count: 1045,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MQ',
            name: 'Медицинские услуги',
            path: 'elanlar/xidmetler/tibbi-xidmetler',
            icon: null,
            __typename: 'Category',
            count: 138,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OQ',
            name: 'Другое',
            path: 'elanlar/xidmetler/diger-xidmetler',
            icon: null,
            __typename: 'Category',
            count: 350,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
              name: 'Услуги и бизнес',
              count: 32389,
              path: 'elanlar/xidmetler',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
        name: 'Личные вещи',
        path: 'elanlar/sexsi-esyalar',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
        __typename: 'Category',
        metaDescription:
          'Одежда и обувь, часы и украшения, аксессуары, красота и здоровье, утерянные вещи',
        count: 26966,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNw',
            name: 'Одежда и обувь',
            path: 'elanlar/sexsi-esyalar/geyim-ayaqqabilar',
            icon: null,
            __typename: 'Category',
            count: 10594,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Личные вещи',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNg',
            name: 'Часы и украшения',
            path: 'elanlar/sexsi-esyalar/saatlar-zinet-esyalari',
            icon: null,
            __typename: 'Category',
            count: 5829,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Личные вещи',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwOA',
            name: 'Аксессуары',
            path: 'elanlar/sexsi-esyalar/aksesuarlar',
            icon: null,
            __typename: 'Category',
            count: 3586,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Личные вещи',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOA',
            name: 'Красота и здоровье',
            path: 'elanlar/sexsi-esyalar/gozellik-saglamliq',
            icon: null,
            __typename: 'Category',
            count: 6077,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Личные вещи',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0Mw',
            name: 'Утерянные вещи',
            path: 'elanlar/sexsi-esyalar/itmis-esyalar',
            icon: null,
            __typename: 'Category',
            count: 34,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Личные вещи',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OA',
            name: 'Электронные сигареты и системы нагревания табака',
            path: 'elanlar/sexsi-esyalar/elektron-siqaretler',
            icon: null,
            __typename: 'Category',
            count: 846,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
              name: 'Личные вещи',
              count: 26966,
              path: 'elanlar/sexsi-esyalar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
        name: 'Хобби и отдых',
        path: 'elanlar/hobbi-ve-asude',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
        __typename: 'Category',
        metaDescription:
          'Билеты и путешествия, велосипеды, коллекционирование, спорт и отдых, музыкальные инструменты, книги и журналы, знакомства, охота и рыбалка',
        count: 20386,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMg',
            name: 'Билеты и путешествия',
            path: 'elanlar/hobbi-ve-asude/turlar-ve-biletler',
            icon: null,
            __typename: 'Category',
            count: 155,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMw',
            name: 'Велосипеды',
            path: 'elanlar/hobbi-ve-asude/velosipedler',
            icon: null,
            __typename: 'Category',
            count: 4006,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNA',
            name: 'Коллекции',
            path: 'elanlar/hobbi-ve-asude/kolleksiyalar',
            icon: null,
            __typename: 'Category',
            count: 2516,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNQ',
            name: 'Музыкальные инструменты',
            path: 'elanlar/hobbi-ve-asude/musiqi-aletleri',
            icon: null,
            __typename: 'Category',
            count: 4015,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNg',
            name: 'Спорт и отдых',
            path: 'elanlar/hobbi-ve-asude/idman-ve-asude',
            icon: null,
            __typename: 'Category',
            count: 6486,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNw',
            name: 'Книги и журналы',
            path: 'elanlar/hobbi-ve-asude/kitab-ve-jurnallar',
            icon: null,
            __typename: 'Category',
            count: 1774,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOA',
            name: 'Кемпинг, охота и рыбалка',
            path: 'elanlar/hobbi-ve-asude/kempinq-ovculuq-baliqciliq',
            icon: null,
            __typename: 'Category',
            count: 1161,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNw',
            name: 'Знакомства',
            path: 'elanlar/hobbi-ve-asude/tanisliq',
            icon: null,
            __typename: 'Category',
            count: 273,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
              name: 'Хобби и отдых',
              count: 20386,
              path: 'elanlar/hobbi-ve-asude',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
        name: 'Детский мир',
        path: 'elanlar/usaqlar-ucun',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
        __typename: 'Category',
        metaDescription:
          'Автокресла, игрушки, детские коляски и автомобили, детская одежда, мебель, питание, для школьников и другое',
        count: 12082,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OQ',
            name: 'Автокресла',
            path: 'elanlar/usaqlar-ucun/avtomobil-oturacaqlari',
            icon: null,
            __typename: 'Category',
            count: 229,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MA',
            name: 'Игрушки',
            path: 'elanlar/usaqlar-ucun/oyuncaqlar',
            icon: null,
            __typename: 'Category',
            count: 2333,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MQ',
            name: 'Детские коляски',
            path: 'elanlar/usaqlar-ucun/usaq-arabalari',
            icon: null,
            __typename: 'Category',
            count: 1677,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Nw',
            name: 'Детские автомобили',
            path: 'elanlar/usaqlar-ucun/usaq-avtomobilleri',
            icon: null,
            __typename: 'Category',
            count: 872,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mw',
            name: 'Детские кроватки и колыбели',
            path: 'elanlar/usaqlar-ucun/usaq-carpayilari-ve-beshikler',
            icon: null,
            __typename: 'Category',
            count: 1625,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Ng',
            name: 'Детские переноски',
            path: 'elanlar/usaqlar-ucun/usaq-dasiyicilari',
            icon: null,
            __typename: 'Category',
            count: 176,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mg',
            name: 'Детская одежда',
            path: 'elanlar/usaqlar-ucun/usaq-geyimleri',
            icon: null,
            __typename: 'Category',
            count: 1904,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mw',
            name: 'Детская мебель',
            path: 'elanlar/usaqlar-ucun/mebel',
            icon: null,
            __typename: 'Category',
            count: 457,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NA',
            name: 'Детское питание и кормление',
            path: 'elanlar/usaqlar-ucun/usaq-yemekleri',
            icon: null,
            __typename: 'Category',
            count: 137,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NQ',
            name: 'Детские горки и игровые площадки',
            path: 'elanlar/usaqlar-ucun/usaq-suruskenleri-ve-oyun-meydancalari',
            icon: null,
            __typename: 'Category',
            count: 227,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mg',
            name: 'Манежи',
            path: 'elanlar/usaqlar-ucun/manejler',
            icon: null,
            __typename: 'Category',
            count: 138,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OQ',
            name: 'Для школьников',
            path: 'elanlar/usaqlar-ucun/mekteb-levazimatlari',
            icon: null,
            __typename: 'Category',
            count: 1288,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MA',
            name: 'Xодунки',
            path: 'elanlar/usaqlar-ucun/yurutecler',
            icon: null,
            __typename: 'Category',
            count: 264,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOA',
            name: 'Купание и гигиена',
            path: 'elanlar/usaqlar-ucun/usaq-gigiyenasi',
            icon: null,
            __typename: 'Category',
            count: 159,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOQ',
            name: 'Детский текстиль',
            path: 'elanlar/usaqlar-ucun/usaq-tekstili',
            icon: null,
            __typename: 'Category',
            count: 107,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NA',
            name: 'Детское сиденье для кормления',
            path: 'elanlar/usaqlar-ucun/qidalanma-ucun-usaq-oturacaqlar',
            icon: null,
            __typename: 'Category',
            count: 402,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NQ',
            name: 'Другое',
            path: 'elanlar/usaqlar-ucun/her-sey',
            icon: null,
            __typename: 'Category',
            count: 87,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
              name: 'Детский мир',
              count: 12082,
              path: 'elanlar/usaqlar-ucun',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
        name: 'Животные',
        path: 'elanlar/heyvanlar',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
        __typename: 'Category',
        metaDescription:
          'Собаки, кошки, птицы, аквариум и рыбы, другие животные, товары для животных',
        count: 10473,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NA',
            name: 'Собаки',
            path: 'elanlar/heyvanlar/itler',
            icon: null,
            __typename: 'Category',
            count: 2081,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NQ',
            name: 'Кошки',
            path: 'elanlar/heyvanlar/pisikler',
            icon: null,
            __typename: 'Category',
            count: 1438,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Ng',
            name: 'Птицы',
            path: 'elanlar/heyvanlar/quslar',
            icon: null,
            __typename: 'Category',
            count: 2578,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Nw',
            name: 'Аквариумы и рыбы',
            path: 'elanlar/heyvanlar/baliqlar-akvariumlar',
            icon: null,
            __typename: 'Category',
            count: 1443,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OQ',
            name: 'С/Х животные',
            path: 'elanlar/heyvanlar/kt-heyvanlari',
            icon: null,
            __typename: 'Category',
            count: 565,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMA',
            name: 'Товары для животных',
            path: 'elanlar/heyvanlar/heyvanlar-ucun-mehsullar',
            icon: null,
            __typename: 'Category',
            count: 1975,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OA',
            name: 'Другие животные',
            path: 'elanlar/heyvanlar/diger-heyvanlar',
            icon: null,
            __typename: 'Category',
            count: 82,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMA',
            name: 'Кролики',
            path: 'elanlar/heyvanlar/dovsanlar',
            icon: null,
            __typename: 'Category',
            count: 161,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMQ',
            name: 'Лошади',
            path: 'elanlar/heyvanlar/atlar',
            icon: null,
            __typename: 'Category',
            count: 95,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMg',
            name: 'Грызуны',
            path: 'elanlar/heyvanlar/gemiriciler',
            icon: null,
            __typename: 'Category',
            count: 55,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
              name: 'Животные',
              count: 10473,
              path: 'elanlar/heyvanlar',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
      {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
        name: 'Работа',
        path: 'elanlar/is-elanlari',
        icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
        __typename: 'Category',
        metaDescription: 'Вакансии, ищу работу',
        count: 8488,
        propertyOptions: null,
        parent: {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
          name: 'Все категории',
          path: 'elanlar',
          icon: null,
          __typename: 'Category',
          count: 405703,
        },
        children: [
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OA',
            name: 'Вакансии',
            path: 'elanlar/is-elanlari/vakansiyalar',
            icon: null,
            __typename: 'Category',
            count: 6282,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
              name: 'Работа',
              count: 8488,
              path: 'elanlar/is-elanlari',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
          {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OQ',
            name: 'Ищу работу',
            path: 'elanlar/is-elanlari/is-axtariram',
            icon: null,
            __typename: 'Category',
            count: 2206,
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
              name: 'Работа',
              count: 8488,
              path: 'elanlar/is-elanlari',
              icon: 'https://tap.azstatic.com/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
              __typename: 'Category',
              parent: {
                id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
                name: 'Все категории',
                count: 405703,
                path: 'elanlar',
                icon: null,
                __typename: 'Category',
              },
            },
          },
        ],
      },
    ],
  },
};

export const getCategoriesMock = (locale: string) =>
  locale === 'ru' ? getCategoriesMockRu : getCategoriesMockAz;
