import React, { useEffect, useRef, useState } from 'react';
import { CatalogueListItem } from '@/components/CatalogueListItem/CatalogueListItem';
import { useTranslation } from 'react-i18next';
import { CatalogueDrawer } from '@/components/CatalogueDrawer/CatalogueDrawer';
import {
  BaseCategory,
  GetBaseCategoriesResponse,
} from '@/api/types/categories.types';
import {
  ALL_CATEGORIES_ID,
  ALL_SHOPS_ID,
  STORAGE_KEY_REMOVING_DELAY,
} from '@/components/CatalogueScrollGrid/CatalogueScrollGrid.constants';
import { useGoToPageWithSaveScrollPosition } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { useQuery } from '@apollo/client';
import { GET_BASE_CATEGORIES } from '@/api/queries/categories.queries';
import { getCategoriesMock } from '@/__e2e__/mock/getCategoriesMock';
import * as S from './CatalogueScrollGrid.styles';

export const CatalogueScrollGrid = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const goToPageWithSaveScrollPosition = useGoToPageWithSaveScrollPosition();

  const { data: { categories = [] } = {}, loading: isCategoriesLoading } =
    useQuery<GetBaseCategoriesResponse>(GET_BASE_CATEGORIES);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const catalogueScrollGridRef = useRef<HTMLDivElement>(null);

  function toggleDrawer() {
    setIsDrawerOpen(prev => !prev);
  }

  function saveScrollLeftToLS() {
    if (catalogueScrollGridRef.current?.scrollLeft) {
      localStorage.setItem(
        STORAGE_KEYS.CATALOGUE_SCROLL_LEFT,
        String(catalogueScrollGridRef.current.scrollLeft)
      );
    }
  }

  function restoreScrollLeftFromLS() {
    const scrollLeft =
      Number(localStorage.getItem(STORAGE_KEYS.CATALOGUE_SCROLL_LEFT)) || 0;

    if (catalogueScrollGridRef.current && scrollLeft) {
      catalogueScrollGridRef.current.scrollLeft = scrollLeft;

      setTimeout(() => {
        localStorage.removeItem(STORAGE_KEYS.CATALOGUE_SCROLL_LEFT);
      }, STORAGE_KEY_REMOVING_DELAY);
    }
  }

  function goWithSavingDataToLS(path?: string) {
    goToPageWithSaveScrollPosition(path);
    saveScrollLeftToLS();
  }

  useEffect(() => {
    restoreScrollLeftFromLS();
  }, []);

  const finalCategories = (
    categories.length ? categories : getCategoriesMock(language).data.categories
  ) as BaseCategory[];

  return (
    <>
      <S.Box data-testid="catalogue-scroll-grid" ref={catalogueScrollGridRef}>
        <CatalogueListItem
          item={
            {
              id: ALL_CATEGORIES_ID,
              icon: 'all-categories.png',
              name: t('catalogue.catalogue'),
            } as BaseCategory
          }
          onClick={toggleDrawer}
          isHorizontalView
          isLoading={isCategoriesLoading}
        />

        {finalCategories.map(item => (
          <CatalogueListItem
            key={item.id}
            item={item}
            onClick={() => goWithSavingDataToLS(`/${item.path}`)}
            isHorizontalView
            isLoading={isCategoriesLoading}
          />
        ))}

        <CatalogueListItem
          data-stat="header-category-shop"
          item={
            {
              id: ALL_SHOPS_ID,
              icon: 'shop.png',
              name: t('catalogue.stores'),
            } as BaseCategory
          }
          isHorizontalView
          isLoading={isCategoriesLoading}
          isStoresIcon
          onClick={() => goWithSavingDataToLS()}
        />
      </S.Box>

      <CatalogueDrawer
        isOpen={isDrawerOpen}
        close={toggleDrawer}
        categories={finalCategories}
        hasSwitchCheckbox={false}
      />
    </>
  );
};
