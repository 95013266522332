import styled from 'styled-components';
import { CustomLink } from '@/components/CustomLink/CustomLink';

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px 5px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  position: relative;
  z-index: 10;
`;

export const NewAdButton = styled(CustomLink)`
  display: flex;
`;
