import { useRouter } from 'next/router';

export function useQueryParam<T = string>(
  name: string
): [T | undefined, boolean] {
  const router = useRouter();

  let param = router.query?.[name];

  if (Array.isArray(param)) {
    // eslint-disable-next-line prefer-destructuring
    param = param[0];
  }

  return [param as T | undefined, router?.isReady];
}
