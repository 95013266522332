import React from 'react';
import { Button } from '@/components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { getReversedLangDefinition } from '@/utils/helpers';
import { useChangeLang } from '@/components/ChangeLang/useChangeLang';

interface ChangeLangProps {
  isShort?: boolean;
}

export const ChangeLang: React.FC<ChangeLangProps> = ({ isShort }) => {
  const { i18n } = useTranslation();
  const changeLang = useChangeLang();

  return (
    <Button
      appearance="transparent"
      onClick={changeLang}
      hasPadding={false}
      isAutoWidth
      data-testid="change-lang"
    >
      <Typography.Body>
        {isShort
          ? getReversedLangDefinition(i18n.language).short.toUpperCase()
          : getReversedLangDefinition(i18n.language).long}
      </Typography.Body>
    </Button>
  );
};
