import { STORAGE_KEYS } from '@/constants/storageKeys';

export function calculateBannersHeight(isOut = true) {
  let bannersHeight = 0;
  const topBanner = document.querySelector('.lotriver-top-banner');
  const bottomBanners = document.querySelectorAll('.lotriver-bottom-banner');
  const scammerBanner = document.querySelector('#js-lotriver-banner-scammer');
  bottomBanners.forEach(el => {
    if ((el as HTMLElement)?.offsetTop < window.scrollY) {
      bannersHeight += el.clientHeight;
    }
  });

  if (isOut) {
    if ((topBanner as HTMLElement)?.offsetTop < window.scrollY) {
      bannersHeight += topBanner?.clientHeight ?? 0;
    }

    if ((scammerBanner as HTMLElement)?.offsetTop < window.scrollY) {
      bannersHeight += scammerBanner?.clientHeight ?? 0;
    }
  } else {
    bannersHeight += topBanner?.clientHeight ?? 0;
    bannersHeight += scammerBanner?.clientHeight ?? 0;
  }

  return bannersHeight;
}

export function setCurrentScrollPosition(isSetToZero = false) {
  localStorage.setItem(
    STORAGE_KEYS.LAST_SCROLL_POSITION,
    isSetToZero ? '0' : String(window.scrollY)
  );
}

export function setBannersHeight() {
  const bannersHeight = calculateBannersHeight(false);
  localStorage.setItem(
    STORAGE_KEYS.PREVIOUS_BANNERS_HEIGHT,
    String(bannersHeight)
  );
}

export function clearStoredScrollPositionData() {
  localStorage.removeItem(STORAGE_KEYS.LAST_SCROLL_POSITION);
  localStorage.removeItem(STORAGE_KEYS.PREVIOUS_BANNERS_HEIGHT);
}

export function clearStoredPremiumAdsData() {
  localStorage.removeItem(STORAGE_KEYS.PREMIUM_ADS);
  localStorage.removeItem(STORAGE_KEYS.PREMIUM_ADS_NEXT_CURSOR);
}
