import styled, { keyframes } from 'styled-components';
import { SpinnerSize } from '@/components/Spinner/Spinner.types';

const ITEMS_COUNT = 8;
const ANIMATION_DURATION = '1.2s';

function getSizeValues(size: SpinnerSize) {
  switch (size) {
    case 'l': {
      return {
        height: '40px',
        itemSize: '5px',
      };
    }
    case 'm': {
      return {
        height: '30px',
        itemSize: '4px',
      };
    }
    default: {
      return {
        height: '20px',
        itemSize: '3px',
      };
    }
  }
}

const fade = keyframes`
  0%, 10%, 100% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
`;

export const SpinnerBox = styled.div<{ $size: SpinnerSize }>`
  position: relative;
  height: ${({ $size }) => getSizeValues($size).height};
  width: ${({ $size }) => getSizeValues($size).height};
  aspect-ratio: 1;
`;

export const SpinnerItem = styled.div<{ $size: SpinnerSize }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: ${({ $size }) => getSizeValues($size).itemSize};
    height: 30%;
    background-color: #dbe0e9;
    border-radius: 5px;
    animation: ${fade} ${ANIMATION_DURATION} infinite ease-in-out both;
  }

  ${Array.from({ length: ITEMS_COUNT - 1 }, (_, i) => i + 2).map(
    i => `
      &:nth-child(${i}) {
        transform: rotate(${(360 / ITEMS_COUNT) * (i - 1)}deg);
      }
    `
  )}

  ${Array.from({ length: ITEMS_COUNT - 1 }, (_, i) => i + 2).map(
    i => `
      &:nth-child(${i}):before {
        animation-delay: calc(-${ANIMATION_DURATION} + ${ANIMATION_DURATION} / ${ITEMS_COUNT} * ( ${
      i - 1
    } ));
      }
    `
  )}
`;
