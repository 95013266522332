import { READY_ROUTES_FOR_PRODUCTION, ROUTES } from '@/constants/routes';
import { LinkProps } from 'next/link';
import type { UrlObject } from 'url';

export function buildURL(data: UrlObject) {
  const baseUrl = window.location.origin;
  const url = new URL(baseUrl + (data.pathname || ''));

  if (data.query) {
    for (const [key, value] of Object.entries(data.query)) {
      if (value != null) {
        url.searchParams.append(key, value.toString());
      }
    }
  }

  if (data.hash) {
    url.hash = data.hash;
  }

  return url.href;
}

export function checkRoute(href: LinkProps['href']): {
  isInternal?: boolean;
  hrefString: string;
} {
  const isHrefString = typeof href === 'string';

  const hrefString = isHrefString ? href : href.href || buildURL(href);

  const isInternal = !!READY_ROUTES_FOR_PRODUCTION.find(
    r => hrefString === r || (r === ROUTES.ad && hrefString.includes(ROUTES.ad))
  );

  return { isInternal, hrefString };
}
