import React, { MutableRefObject } from 'react';

export function useCombinedRefs<T>(
  ...refs: Array<null | MutableRefObject<T> | ((instance: T | null) => void)>
): React.MutableRefObject<null | T> {
  const targetRef = React.useRef<T>(null);

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else if (targetRef.current) {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}
