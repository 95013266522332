import styled from 'styled-components';
import { removeScrollbar } from '@/styles/helpers';
import { Chip } from '@/components/UI/Chip/Chip';

export const SubcategoriesScrollGridBox = styled.div`
  ${removeScrollbar()}

  display: flex;
  gap: 5px;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.palette.primary.background};
  padding-bottom: 15px;
`;

export const SubcategoriesScrollGridItem = styled(Chip)`
  &:first-child {
    margin-left: 15px;
  }

  &:last-child {
    margin-right: 15px;
  }
`;
