import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';
import {
  setBannersHeight,
  setCurrentScrollPosition,
} from '@/components/ScrollSaver/ScrollSaver.helpers';
import { useAdsStore } from '@/store/useAdsStore';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';

export function useSaveScrollPositionActions() {
  const router = useRouter();

  const [ads, nextCursor] = useAdsStore(state => [
    state.premiumAdsHomePage,
    state.premiumAdsNextCursorHomePage,
  ]);

  function saveScrollPositionActions(isSetToZero = false) {
    if (router.asPath !== ROUTES.home) {
      return;
    }

    localStorage.setItem(STORAGE_KEYS.PREMIUM_ADS, JSON.stringify(ads));
    localStorage.setItem(STORAGE_KEYS.PREMIUM_ADS_NEXT_CURSOR, nextCursor);

    setBannersHeight();
    setCurrentScrollPosition(isSetToZero);
  }

  return saveScrollPositionActions;
}

export function useGoToPageWithSaveScrollPosition() {
  const saveScrollPositionActions = useSaveScrollPositionActions();
  const customPush = useCustomPush();

  return function goToPageWithSaveScrollPosition(
    href = ROUTES.shops,
    isSetToZero = false
  ) {
    saveScrollPositionActions(isSetToZero);
    customPush(href);
  };
}
