import { create } from 'zustand';

export type PageTopControlsPosition = 'fixed' | 'relative';

interface PageTopControlsStore {
  topControlsHeight: number;
  setTopControlsHeight: (value: number) => void;
  canBeFixed: boolean;
  setCanBeFixed: (value: boolean) => void;
  topControlsPosition: PageTopControlsPosition;
  setTopControlsPosition: (value: PageTopControlsPosition) => void;
}

export const usePageTopControlsStore = create<PageTopControlsStore>(set => ({
  topControlsHeight: 0,
  setTopControlsHeight: value => set({ topControlsHeight: value }),
  canBeFixed: true,
  setCanBeFixed: value => set({ canBeFixed: value }),
  topControlsPosition: 'relative',
  setTopControlsPosition: value => set({ topControlsPosition: value }),
}));
