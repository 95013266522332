import styled, { css } from 'styled-components';
import { ChipAppearance, ChipSize } from '@/components/UI/Chip/Chip.types';

function getStylesOnSize(size: ChipSize) {
  switch (size) {
    case 's': {
      return css`
        padding: 5px;
        font-size: 12px;
      `;
    }
    case 'm': {
      return css`
        padding: 5px 10px;
        font-size: 12px;
      `;
    }
    default: {
      return css`
        padding: 10px 15px;
        font-size: 14px;
      `;
    }
  }
}

function getColors(appearance: ChipAppearance) {
  switch (appearance) {
    case 'inactive': {
      return css`
        color: ${({ theme }) => theme.palette.primary.labels};
        background-color: ${({ theme }) => theme.palette.primary.border};
      `;
    }
    case 'error': {
      return css`
        color: ${({ theme }) => theme.palette.system.red};
        background-color: rgba(255, 105, 124, 0.25);
      `;
    }
    case 'primary': {
      return css`
        color: ${({ theme }) => theme.palette.system.white};
        background-color: ${({ theme }) => theme.palette.brand.main};
      `;
    }
    case 'secondaryTransparent': {
      return css`
        color: ${({ theme }) => theme.palette.system.blue};
        background-color: ${({ theme }) => theme.palette.system.lightBlue};
        border: 1px solid ${({ theme }) => theme.palette.system.blue};
      `;
    }
    case 'green': {
      return css`
        color: ${({ theme }) => theme.palette.system.white};
        background-color: ${({ theme }) => theme.palette.system.green};
      `;
    }
    case 'gray': {
      return css`
        color: ${({ theme }) => theme.palette.primary.labels};
        background-color: ${({ theme }) => theme.palette.secondary.background};
      `;
    }
    default: {
      return css`
        color: ${({ theme }) => theme.palette.primary.labels};
        background-color: ${({ theme }) => theme.palette.primary.background};
        border: 1px solid ${({ theme }) => theme.palette.primary.border};
      `;
    }
  }
}

export const ChipBox = styled.div<{
  $appearance: ChipAppearance;
  $size: ChipSize;
  $isRounded?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ $isRounded }) => ($isRounded ? '20px' : '7px')};
  white-space: nowrap;
  font-weight: 400;
  border: 0;

  ${({ $size }) => getStylesOnSize($size)}

  ${({ $appearance }) => getColors($appearance)}
`;
