import styled, { css } from 'styled-components';
import {
  ButtonAppearance,
  ButtonShape,
  ButtonSize,
} from '@/components/UI/Button/Button.types';

function getWidth(
  shape?: ButtonShape,
  isFullWidth?: boolean,
  isAutoWidth?: boolean
) {
  switch (true) {
    case isAutoWidth: {
      return 'auto';
    }
    case shape === 'square': {
      return '40px';
    }
    case isFullWidth: {
      return '100%';
    }
    default: {
      return 'auto';
    }
  }
}

function getPadding(
  shape?: ButtonShape,
  hasPadding?: boolean,
  isLoading?: boolean
) {
  switch (true) {
    case shape === 'square' || !hasPadding: {
      return 0;
    }
    case isLoading: {
      return '15px 16px';
    }

    default: {
      return '16px';
    }
  }
}

function getStylesForAppearance(appearance?: ButtonAppearance) {
  switch (true) {
    case appearance === 'transparent': {
      return css`
        background-color: transparent;
        border: 0;
      `;
    }
    case appearance === 'primary': {
      return css`
        background-color: ${({ theme }) => theme.palette.brand.main};
        border: 0;
        color: ${({ theme }) => theme.palette.system.white};
      `;
    }
    case appearance === 'primaryOutlined': {
      return css`
        background-color: ${({ theme }) => theme.palette.primary.background};
        border-color: ${({ theme }) => theme.palette.brand.main};
        color: ${({ theme }) => theme.palette.brand.main};
      `;
    }
    case appearance === 'primaryText': {
      return css`
        background-color: ${({ theme }) => theme.palette.primary.background};
        border-color: transparent;
        color: ${({ theme }) => theme.palette.brand.main};
      `;
    }
    case appearance === 'secondary': {
      return css`
        background-color: ${({ theme }) => theme.palette.system.blue};
        border: 0;
        color: ${({ theme }) => theme.palette.system.white};
      `;
    }
    case appearance === 'secondaryLight': {
      return css`
        background-color: ${({ theme }) => theme.palette.system.lightBlue};
        border: 0;
        color: ${({ theme }) => theme.palette.system.blue};
      `;
    }
    case appearance === 'secondaryOutlined': {
      return css`
        background-color: ${({ theme }) => theme.palette.primary.background};
        border-color: ${({ theme }) => theme.palette.system.blue};
        color: ${({ theme }) => theme.palette.system.blue};
      `;
    }
    case appearance === 'secondaryText': {
      return css`
        background-color: ${({ theme }) => theme.palette.primary.background};
        border-color: transparent;
        color: ${({ theme }) => theme.palette.system.blue};
      `;
    }
    case appearance === 'secondaryWhite': {
      return css`
        color: ${({ theme }) => theme.palette.system.blue};
      `;
    }
    case appearance === 'green': {
      return css`
        background-color: ${({ theme }) => theme.palette.system.green};
        border-color: transparent;
        color: ${({ theme }) => theme.palette.system.white};
      `;
    }
    case appearance === 'red': {
      return css`
        background-color: ${({ theme }) => theme.palette.system.red};
        border-color: transparent;
        color: ${({ theme }) => theme.palette.system.white};
      `;
    }
    case appearance === 'gray': {
      return css`
        background-color: transparent;
        border-color: transparent;
        color: ${({ theme }) => theme.palette.secondary.labels};
      `;
    }
    case appearance === 'defaultOutlined': {
      return css`
        background-color: transparent;
      `;
    }

    default: {
      return css``;
    }
  }
}

function getStylesForSize(size?: ButtonSize, hasPadding?: boolean) {
  switch (true) {
    case !hasPadding: {
      return css`
        height: auto;
      `;
    }
    case size === 's': {
      return css`
        height: 40px;
        padding: 10px;
      `;
    }
    case size === 'm': {
      return css`
        height: 50px;
      `;
    }
    case size === 'l': {
      return css`
        height: 60px;
      `;
    }
    default: {
      return css`
        height: auto;
      `;
    }
  }
}

export const ButtonBox = styled.button<{
  $shape?: ButtonShape;
  $appearance?: ButtonAppearance;
  $isFullWidth?: boolean;
  $isAutoWidth?: boolean;
  $hasPadding?: boolean;
  $isLoading?: boolean;
  $size?: ButtonSize;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: ${({ $shape, $isFullWidth, $isAutoWidth }) =>
    getWidth($shape, $isFullWidth, $isAutoWidth)};
  padding: ${({ $shape, $hasPadding, $isLoading }) =>
    getPadding($shape, $hasPadding, $isLoading)};
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.secondary.background};
  border: 1px solid ${({ theme }) => theme.palette.primary.border};
  appearance: none;

  ${({ $appearance }) => getStylesForAppearance($appearance)}

  ${({ $hasPadding, $size }) => getStylesForSize($size, $hasPadding)}

  ${({ $shape }) =>
    $shape === 'square' &&
    css`
      aspect-ratio: 1;
    `}

  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      flex-shrink: 1;
    `}
  
  &:disabled {
    opacity: 0.4;
  }
`;
