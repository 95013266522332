import React from 'react';
import * as S from './RequiredStar.styles';

export const RequiredStar = () => {
  return (
    <S.RequiredStarBox $variant="1" as="span" data-testid="required-star">
      *
    </S.RequiredStarBox>
  );
};
