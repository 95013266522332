import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import NoSsr from '@/components/NoSsr/NoSsr';

export const Portal = ({ children }: PropsWithChildren) => {
  if (typeof document === 'undefined') {
    return null;
  }
  // @ts-ignore
  return <NoSsr>{createPortal(children, document.body)}</NoSsr>;
};
