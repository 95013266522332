import { gql } from '@apollo/client';

export const GET_BASE_CATEGORIES = gql`
  fragment BaseCategoryFields on Category {
    id
    name
    count
    path
    icon
  }

  query GetCategories {
    categories {
      ...BaseCategoryFields
      metaDescription
      propertyOptions {
        id
        propertyId
        name
      }
      parent {
        ...BaseCategoryFields
      }
      children {
        id
        name
        path
        count
        icon
        parent {
          ...BaseCategoryFields
          parent {
            ...BaseCategoryFields
          }
        }
      }
    }
  }
`;
