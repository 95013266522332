import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { CloseIcon } from '@/components/UI/Icons/Icons';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import {
  DrawerProps,
  DrawerPropsWithLeftBtn,
} from '@/components/Drawer/Drawer.types';
import { useTranslation } from 'react-i18next';
import {
  DrawerSearch,
  DrawerSearchRef,
} from '@/components/Drawer/DrawerSearch';
import { Portal } from '@/components/UI/Portal/Portal';
import NoSsr from '@/components/NoSsr/NoSsr';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';
import * as S from './Drawer.styles';

export const Drawer: React.FC<
  PropsWithChildren<DrawerProps | DrawerPropsWithLeftBtn>
> = ({
  children,
  isOpen,
  close,
  title,
  subtitle,
  subtitleVariant,
  left,
  right,
  bottom,
  hasBottomMargin = true,
  hasBottomBorder = true,
  onSearch,
  searchPlaceholder,
  isInPortal,
  className,
  dataTestId,
}) => {
  useBodyOverflow(isOpen);

  const { t } = useTranslation();
  const searchRef = useRef<DrawerSearchRef>(null);

  useEffect(() => {
    searchRef.current?.clear();
  }, [isOpen]);

  function renderDrawer() {
    return (
      <S.DrawerBox
        data-testid={dataTestId}
        $isOpen={isOpen}
        className={className}
      >
        <S.DrawerItem
          $isHead
          $isLast={!subtitle && hasBottomBorder && !onSearch}
          $hasBottomMargin={hasBottomMargin && !onSearch}
          as="div"
        >
          <S.DrawerClose
            data-testid="drawer-close-button"
            $shape="square"
            $appearance="transparent"
            onClick={left ? left.onClick : close}
          >
            {left ? left.icon : <CloseIcon />}
          </S.DrawerClose>

          {typeof title === 'string' ? (
            <Typography.Header $variant="3">{title}</Typography.Header>
          ) : title?.iconSrc && title?.text ? (
            <S.DrawerTitle $variant="3">
              <IconBox $src={title?.iconSrc} width={15} height={13} />
              {title?.text}
            </S.DrawerTitle>
          ) : null}

          {right && (
            <S.RightAction appearance="primaryText" onClick={right.onClick}>
              {right?.title ?? t('common.reset')}
            </S.RightAction>
          )}
        </S.DrawerItem>

        {subtitle && (
          <S.DrawerSubtitle $variant={subtitleVariant ?? '1'}>
            {subtitle}
          </S.DrawerSubtitle>
        )}

        {onSearch && (
          <DrawerSearch
            onSearch={onSearch}
            placeholder={searchPlaceholder}
            ref={searchRef}
          />
        )}

        <S.DrawerChildren>{children}</S.DrawerChildren>

        {bottom && <S.DrawerBottom>{bottom}</S.DrawerBottom>}
      </S.DrawerBox>
    );
  }

  if (isInPortal) {
    return (
      <Portal>
        <NoSsr>{renderDrawer()}</NoSsr>
      </Portal>
    );
  }

  return renderDrawer();
};
