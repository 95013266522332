import styled from 'styled-components';
import { InputBox } from '@/components/UI/Input/Input.styles';
import { Button } from '@/components/UI/Button/Button';

export const Box = styled.div<{
  $hasPaddingTop?: boolean;
}>`
  display: flex;
  gap: 5px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  padding: ${({ $hasPaddingTop }) => ($hasPaddingTop ? '15px' : '0 15px 12px')};
  position: relative;
  z-index: 10;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.secondary.background};
  border: 1px solid ${({ theme }) => theme.palette.primary.border};
`;

export const Input = styled(InputBox)`
  padding: 0 10px;
`;

export const SubmitButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid ${({ theme }) => theme.palette.primary.border};
  margin-left: 4px;
`;
