import React, { useEffect, useState } from 'react';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { SelectAngle } from '@/components/UI/Select/Select.styles';
import * as S from '@/components/SearchSuggestions/SearchSuggestions.styles';
import { cutSentenceFor2Parts } from '@/utils/helpers';
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';
import { useQuery } from '@apollo/client';
import { GET_SEARCH_SUGGESTIONS } from '@/api/queries/searchSuggestions.queries';
import {
  GetSearchSuggestionResponse,
  SearchSuggestion,
} from '@/api/types/searchSuggestions.types';
import { useSearch } from '@/hooks/useSearch';
import { Portal } from '@/components/UI/Portal/Portal';
import { usePageTopControlsStore } from '@/store/usePageTopControlsStore';
import { useIsTopBannerVisible } from '@/hooks/useIsTopBannerVisible';
import { useDebounce } from '@/hooks/useDebounce';
import { Props } from '@/components/SearchSuggestions/SearchSuggestions.types';
import { useSearchStore } from '@/store/useSearchStore';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { useScrollVisibility } from '@/hooks/useScrollVisibility';

export const SearchSuggestions = ({
  query,
  setQuery,
  isInteractionHappened,
  setIsInteractionHappened,
  scrollToInput,
}: Props) => {
  const router = useRouter();
  const search = useSearch();
  const { isTopOfPage } = useScrollVisibility();
  const isTopBannerVisible = useIsTopBannerVisible();
  const debouncedQuery = useDebounce(query.trim());
  const [results, setResults] = useState<SearchSuggestion[]>([]);
  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);

  const [isSuggestionsOpened, setIsSuggestionsOpened] = useSearchStore(
    state => [state.isSuggestionsOpened, state.setIsSuggestionsOpened]
  );
  const [setTopControlsCanBeFixed, topControlsPosition] =
    usePageTopControlsStore(state => [
      state.setCanBeFixed,
      state.topControlsPosition,
    ]);

  const isTopControlsFixed = topControlsPosition === 'fixed';
  const isHome = router.pathname === ROUTES.home;

  function onSuggestionClick(suggestion: SearchSuggestion) {
    setIsSuggestionsOpened(false);
    setIsSuggestionClicked(true);
    setQuery(suggestion.value);
    scrollToInput();
    localStorage.setItem(STORAGE_KEYS.NEED_TO_RESTORE_SCROLL_POSITION, 'false');
  }

  useQuery<GetSearchSuggestionResponse>(GET_SEARCH_SUGGESTIONS, {
    skip: !debouncedQuery || (!!debouncedQuery && isSuggestionClicked),
    fetchPolicy: 'no-cache',
    variables: { keywords: debouncedQuery },
    onCompleted: data => {
      setResults(data.searchSuggestions);
    },
  });

  useEffect(() => {
    setIsSuggestionsOpened(
      isSuggestionClicked
        ? false
        : !!results.length && !!query && isInteractionHappened
    );
  }, [results.length, query, isInteractionHappened]);

  useEffect(() => {
    setTopControlsCanBeFixed(
      isSuggestionClicked ? false : !isSuggestionsOpened
    );
    if (!isSuggestionsOpened && !query) {
      setResults([]);
    }
  }, [isSuggestionsOpened, query]);

  useEffect(() => {
    if ((isTopOfPage || isTopControlsFixed) && isSuggestionClicked) {
      search(query);
    }
  }, [isTopOfPage, isSuggestionClicked, isTopControlsFixed]);

  return (
    <Portal>
      <S.OverlayCustom
        $isOpen={isSuggestionsOpened}
        $isHome={isHome}
        data-testid="overlay"
        onClick={() => setIsInteractionHappened(false)}
      />
      <S.Box
        data-testid="search-suggestions"
        $isOpen={isSuggestionsOpened}
        $isHome={isHome}
        $isTopBannerVisible={isTopBannerVisible}
        $isTopControlsFixed={isTopControlsFixed}
      >
        {results.map((suggestion, i, arr) => {
          const [entered, rest] = cutSentenceFor2Parts(
            suggestion.value,
            debouncedQuery
          );

          return (
            <S.Item
              key={suggestion.value}
              $isFirst={!i}
              $isLast={i === arr.length - 1}
              onClick={() => onSuggestionClick(suggestion)}
              data-testid={`search-suggestion-${i}`}
            >
              <S.Text>
                {entered}
                <b>{rest}</b>
              </S.Text>
              <SelectAngle>
                <AngleBackIcon />
              </SelectAngle>
            </S.Item>
          );
        })}
      </S.Box>
    </Portal>
  );
};
