import React, { useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/UI/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import * as S from './DrawerSearch.styles';

interface Props {
  onSearch: (value: string) => void;
  placeholder?: string;
}

export interface DrawerSearchRef {
  clear: () => void;
}

type DrawerSearchFormFields = {
  name: string;
};

export const DrawerSearch = React.forwardRef<DrawerSearchRef, Props>(
  ({ onSearch, placeholder }, drawerSearchRef) => {
    const { t } = useTranslation();
    const { control, reset, watch } = useForm<DrawerSearchFormFields>({
      defaultValues: { name: '' },
    });
    const { name } = watch();

    function clear() {
      reset({ name: '' });
    }

    useImperativeHandle(drawerSearchRef, () => ({ clear }), []);

    useEffect(() => {
      onSearch(name);
    }, [name]);

    return (
      <S.Box>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              appearance="filledWithIcon"
              helperText={fieldState.error?.message}
              hasError={!!fieldState.error}
              clear={clear}
              placeholder={placeholder ?? t('common.search')}
            />
          )}
        />
      </S.Box>
    );
  }
);

DrawerSearch.displayName = 'DrawerSearch';
