import { useBannerStore } from '@/store/useBannerStore/useBannerStore';
import { useAdManagerBannerVisible } from '@/components/Banners/AdManagerBanner.hooks';

export function useIsTopBannerVisible() {
  const isAdriverTopBannerVisible = useBannerStore(
    state => state.isAdriverTopBannerVisible
  );
  const isAdManagerBannerVisible = useAdManagerBannerVisible();

  return isAdriverTopBannerVisible || isAdManagerBannerVisible;
}
