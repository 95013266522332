import { BannerName } from '@/components/Banners/Banners.types';

export const TOP_BANNER_HEIGHT = 100;

export const ADRIVER_SID = '220545';

export const AdriverBannerId = {
  [BannerName.Top]: 'js-lotriver-top-banner',
  [BannerName.Bottom]: 'js-lotriver-bottom-banner4',
};

export const AdManagerBanners = {
  [BannerName.Top]: {
    bannerId: '/22595893243/mobile_top_tap',
    blockId: 'google-buds-top-banner',
    sizes: [320, TOP_BANNER_HEIGHT],
  },
  [BannerName.Card]: {
    bannerId: '/22595893243/tap.az/tap.az_listing_card_166x221',
    blockId: 'google-buds-banner',
    sizes: [166, 221],
  },
  [BannerName.Bottom]: {
    bannerId: '/22595893243/tap.az/mobile_bottom_tap.az',
    blockId: 'google-buds-bottom-banner',
    sizes: [320, 100],
  },
};
