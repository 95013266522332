import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';

export const FiltersDrawerTitle = styled(Typography.Title)`
  color: ${({ theme }) => theme.palette.secondary.labels};
  padding: 15px 0 0;
`;

export const FiltersDrawerPrices = styled.div`
  display: flex;
  gap: 15px;
`;

export const FiltersDrawerSortItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px 0;
`;

export const FiltersDrawerSortItem = styled(Typography.Body)<{
  $isActive: boolean;
}>`
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  border: 1px solid ${({ theme }) => theme.palette.primary.border};
  white-space: nowrap;
  max-width: 45%;
  overflow: hidden;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.palette.brand.main};
      background-color: ${({ theme }) => theme.palette.tertiary.background};
      border: 1px solid ${({ theme }) => theme.palette.brand.main};
    `}
`;

export const FiltersDrawerCTA = styled.div`
  margin-top: auto;
  padding: 15px;
`;
