import React, { useRef } from 'react';
import { RequiredStar } from '@/components/UI/RequiredStar/RequiredStar';
import { CheckboxProps } from '@/components/UI/Checkbox/Checkbox.types';
import { getDataStatAttr } from '@/utils/helpers';
import * as S from './Checkbox.styles';

export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  label,
  labelIcon,
  helperText,
  hasError,
  appearance = 'switch',
  size = 'lg',
  onChangeHook,
  labelPosition = 'left',
  color = 'primary',
  action,
  customContent,
  dataStat,
  hasSwitch = true,
  ...props
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  function handleCheckboxWrapperClick() {
    if (checkboxRef.current) {
      checkboxRef.current.click();
    }
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onChange?.(e);
    onChangeHook?.();
  }

  return (
    <S.Wrapper
      {...getDataStatAttr({ dataStat })}
      $appearance={appearance}
      $size={size}
      $color={color}
      $labelPosition={labelPosition}
      onClick={handleCheckboxWrapperClick}
      className={props.className}
    >
      {action}

      {customContent}

      {label && !labelIcon && (
        <S.Label $variant="1" as="label" htmlFor={props.name}>
          {label} {props.required && <RequiredStar />}
        </S.Label>
      )}

      {label && labelIcon && (
        <S.LabelBox htmlFor={props.name}>
          <S.Label $variant="1" as="span">
            {label} {props.required && <RequiredStar />}
          </S.Label>

          {labelIcon}
        </S.LabelBox>
      )}

      <S.Switch $appearance={appearance} $isVisible={hasSwitch}>
        <S.Box
          {...props}
          onChange={onChange}
          ref={checkboxRef}
          checked={value}
          type="checkbox"
          data-testid="Checkbox"
        />
        <S.Knob $appearance={appearance} />
      </S.Switch>

      {helperText && (
        <S.HelperText $variant="4" $hasError={hasError}>
          {helperText}
        </S.HelperText>
      )}
    </S.Wrapper>
  );
};
