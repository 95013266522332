import { NextRouter, Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const AMOUNT_ON_FIRST_LOAD = 2;

export const useIsRouterFirstLoad = () => {
  const router = useRouter();
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  useEffect(() => {
    if (
      Object.keys(
        (router as NextRouter & { components: Router['components'] }).components
      ).length === AMOUNT_ON_FIRST_LOAD
    ) {
      setIsFirstLoad(true);
    }
  }, []);

  return isFirstLoad;
};
