import styled, { css } from 'styled-components';
import { Overlay } from '@/components/UI/Overlay/Overlay.styles';
import { DrawerItem } from '@/components/Drawer/Drawer.styles';
import { ellipsisMultiline } from '@/styles/helpers';
import {
  TOP_POSITION_HOME,
  TOP_POSITION_SEARCH_RESULTS,
} from '@/components/SearchSuggestions/SearchSuggestions.constants';
import { TOP_BANNER_HEIGHT } from '@/components/Banners/Banners.constants';

export const Box = styled.div<{
  $isOpen?: boolean;
  $isHome?: boolean;
  $isTopBannerVisible?: boolean;
  $isTopControlsFixed?: boolean;
}>`
  position: ${({ $isTopControlsFixed }) =>
    $isTopControlsFixed ? 'fixed' : 'absolute'};
  top: ${({ $isHome, $isTopBannerVisible, $isTopControlsFixed }) =>
    `calc(${
      $isTopBannerVisible && !$isTopControlsFixed ? TOP_BANNER_HEIGHT : 0
    }px + ${$isHome ? TOP_POSITION_HOME : TOP_POSITION_SEARCH_RESULTS}px)`};
  left: 0;
  width: 100%;
  z-index: 10021;
  );

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  > h6 {
    padding: 15px;
  }
`;

export const Item = styled(DrawerItem)`
  justify-content: space-between;
  gap: 15px;
`;

export const Text = styled.p`
  ${ellipsisMultiline(1)};
`;

export const OverlayCustom = styled(Overlay)<{ $isHome?: boolean }>`
  transition: none;
  z-index: 9;
`;
