import React from 'react';
import * as S from '@/components/TopActions/TopActions.styles';
import { BurgerNav } from '@/components/BurgerNav/BurgerNav';
import { PlusIcon } from '@/components/UI/Icons/Icons';
import { ROUTES } from '@/constants/routes';
import { LogoLink } from '@/components/LogoLink/LogoLink';
import { useSaveScrollPositionActions } from '@/components/ScrollSaver/ScrollSaver.hooks';

export const TopActions = () => {
  const saveScrollPositionActions = useSaveScrollPositionActions();

  return (
    <S.Box data-testid="top-actions">
      <BurgerNav />

      <LogoLink />

      <S.NewAdButton
        dataTestId="header-add-new-ad-button"
        onClick={() => saveScrollPositionActions()}
        href={ROUTES.newAd}
      >
        <PlusIcon />
      </S.NewAdButton>
    </S.Box>
  );
};
