import { useLocalStorage } from 'usehooks-ts';
import { DefaultTheme } from 'styled-components';
import { STORAGE_KEYS } from '@/constants/storageKeys';
import { defaultTheme } from '@/styles/defaultTheme';

export const useTheme = () => {
  const [theme] = useLocalStorage<DefaultTheme>(
    STORAGE_KEYS.THEME,
    defaultTheme
  );

  const isDarkTheme = theme.name === 'dark';

  return { isDarkTheme };
};
