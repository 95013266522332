import styled from 'styled-components';
import { removeScrollbar } from '@/styles/helpers';

export const Box = styled.div`
  ${removeScrollbar()}

  display: flex;
  gap: 5px;
  padding: 0 0 15px;
  overflow-x: auto;
  background-color: ${({ theme }) => theme.palette.primary.background};
  width: 100vw;
`;
