import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { ellipsisMultiline } from '@/styles/helpers';

export const SelectBox = styled.div<{
  $hasBg?: boolean;
  $hasBorderBottom?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 60px;

  ${({ $hasBorderBottom }) =>
    $hasBorderBottom &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
    `}

  ${({ $hasBg }) =>
    $hasBg &&
    css`
      background-color: ${({ theme }) => theme.palette.primary.background};
    `}

  img {
    object-fit: contain;
  }
`;

export const SelectValue = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SelectImage = styled.div<{ $isDefaultIcon: boolean }>`
  height: 40px;
  width: 40px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $isDefaultIcon }) =>
    $isDefaultIcon
      ? css`
          background-image: url('/images/categories.svg');
          background-position: center;
          background-size: 17px 17px;
          background-repeat: no-repeat;
        `
      : css`
          border-radius: 7px;
          background-color: ${({ theme }) =>
            theme.palette.secondary.background};
        `}

  img {
    object-fit: contain;
  }
`;

export const SelectTexts = styled.div`
  display: grid;
  align-items: center;
  gap: 2px;
`;

export const SelectLabel = styled(Typography.Body)`
  color: ${({ theme }) => theme.palette.secondary.labels};
`;

export const SelectLabelSmall = styled(Typography.Title)`
  color: ${({ theme }) => theme.palette.secondary.labels};
`;

export const SelectText = styled(Typography.Body)`
  ${ellipsisMultiline(2)}
`;

export const SelectAngle = styled.div`
  transform: rotate(180deg);
`;
