import styled from 'styled-components';
import {
  IconBoxProps,
  IconBoxPropsWithSrc,
} from '@/components/UI/IconBox/IconBox.types';

export const IconBox = styled.div<IconBoxProps | IconBoxPropsWithSrc>`
  background-image: ${({ $filename, $src }) =>
    `url(${$src ?? `/images/${$filename ?? ''}`}`});
  width: ${({ width }) => width ?? 20}px;
  min-width: ${({ width }) => width ?? 20}px;
  height: ${({ height }) => height ?? 20}px;
  background-position: center;
  background-size: ${({ $iconWidth, $iconHeight }) =>
    `${$iconWidth ? `${$iconWidth}px` : 'auto'} ${
      $iconHeight ? `${$iconHeight}px` : 'auto'
    }`};
  background-repeat: no-repeat;
`;
