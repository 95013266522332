import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import NoSsr from '@/components/NoSsr/NoSsr';
import Cookies from 'js-cookie';
import * as S from './AndroidAppBanner.styles';

export const AndroidAppBanner = () => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);
  const hostnameModified =
    typeof window !== 'undefined' &&
    `.${window.location.hostname?.replace(/^ru./, '')}`;

  function handleCloseClick() {
    Cookies.set('appBannerShow', 'true', {
      expires: 7,
      domain: hostnameModified || '',
    });
    setShowBanner(false);
  }

  useEffect(() => {
    setShowBanner(!Cookies.get('appBannerShow'));
  }, []);

  return (
    showBanner && (
      <NoSsr>
        <S.BannerContainer>
          <S.BannerClose onClick={handleCloseClick}>close</S.BannerClose>
          <S.Banner>
            <Image
              src="/images/app_banners/app-banner-logo.png"
              alt="Tap.Az"
              width={30}
              height={30}
            />
            <S.BannerText>
              {t('banners.app_banner_title')}
              <S.BannerDescription>
                {t('banners.app_banner_desc')}
              </S.BannerDescription>
            </S.BannerText>
          </S.Banner>
          <S.BannerLink
            href="intent://tap.az/#Intent;scheme=tapaz;package=org.tapaz.android;end"
            onClick={handleCloseClick}
          >
            {t('banners.app_banner_open')}
          </S.BannerLink>
        </S.BannerContainer>
      </NoSsr>
    )
  );
};

AndroidAppBanner.displayName = 'AndroidAppBanner';
