import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { TopActions } from '@/components/TopActions/TopActions';
import { AndroidAppBanner } from '@/components/AndroidAppBanner/AndroidAppBanner';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { CatalogueScrollGrid } from '@/components/CatalogueScrollGrid/CatalogueScrollGrid';
import { useScrollVisibility } from '@/hooks/useScrollVisibility';
import { Header } from '@/components/Header/Header';
import { SubcategoriesScrollGrid } from '@/components/SubcategoriesScrollGrid/SubcategoriesScrollGrid';
import { useRouter } from 'next/router';
import { getAdPagePath } from '@/utils/helpers';
import { usePageTopControlsStore } from '@/store/usePageTopControlsStore';
import { PageTopControlsProps } from '@/components/PageTopControls/PageTopControls.types';
import { TOP_BANNER_HEIGHT } from '@/components/Banners/Banners.constants';
import { useIsTopBannerVisible } from '@/hooks/useIsTopBannerVisible';
import { useMobileDetect } from '@/hooks/useMobileDetect';
import { useQuery } from '@apollo/client';
import { GetBaseCategoriesResponse } from '@/api/types/categories.types';
import { GET_BASE_CATEGORIES } from '@/api/queries/categories.queries';
import * as S from '@/components/PageTopControls/PageTopControls.styles';
import { useSearchStore } from '@/store/useSearchStore';

export const PageTopControls: React.FC<
  PropsWithChildren<PageTopControlsProps>
> = ({
  children,
  hasBurger,
  hasSearch,
  hasCategories,
  headerTitle,
  hasSubcategories,
  isMainHeader,
  headerLeftAction,
  hasLogoLink,
  onInitialRender,
  hasFilters,
  hasAddNew,
  hasDivider,
  hasBorderBottom,
  customHeader,
  isBackRequired,
  hasLeadingAction,
  hasWhiteBg,
  headerNotificationContainer,
  leadingIcon,
  searchDataStat,
}) => {
  const router = useRouter();
  const { isVisible, scrollPos, isTopOfPage } = useScrollVisibility();
  const controlsRef = useRef<HTMLDivElement>(null);
  const isTopBannerVisible = useIsTopBannerVisible();

  const { isAndroid } = useMobileDetect();

  const [isSuggestionsOpened] = useSearchStore(state => [
    state.isSuggestionsOpened,
  ]);
  const [
    topControlsHeight,
    setTopControlsHeight,
    canBeFixed,
    topControlsPosition,
    setTopControlsPosition,
  ] = usePageTopControlsStore(state => [
    state.topControlsHeight,
    state.setTopControlsHeight,
    state.canBeFixed,
    state.topControlsPosition,
    state.setTopControlsPosition,
  ]);

  const { data } = useQuery<GetBaseCategoriesResponse>(GET_BASE_CATEGORIES);
  const categories = data?.categories;

  const subcategories = useMemo(() => {
    const currentPath = getAdPagePath(router.query.slug);
    const currentCategory = categories?.find(c => `/${c.path}` === currentPath);
    return currentCategory ? currentCategory.children : [];
  }, [categories]);

  useEffect(() => {
    if (controlsRef.current) {
      setTopControlsHeight(controlsRef.current.offsetHeight);
      onInitialRender?.(controlsRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const topControlsHeightFinal = isTopBannerVisible
      ? topControlsHeight + TOP_BANNER_HEIGHT
      : topControlsHeight;

    if (scrollPos >= topControlsHeightFinal && canBeFixed) {
      setTopControlsPosition('fixed');
    }

    if (isTopOfPage) {
      setTopControlsPosition('relative');
    }
  }, [scrollPos, topControlsHeight, canBeFixed]);

  return (
    <>
      {topControlsPosition === 'fixed' && (
        <S.HomeViewTopControlsGutter $topControlsHeight={topControlsHeight} />
      )}
      <S.HomeViewTopControls
        ref={controlsRef}
        $isVisible={isVisible || isSuggestionsOpened}
        $position={topControlsPosition}
        $topControlsHeight={topControlsHeight}
        $hasBorderBottom={hasBorderBottom}
      >
        {isAndroid && <AndroidAppBanner />}
        {isMainHeader ? (
          <TopActions />
        ) : (
          <Header
            title={headerTitle}
            hasBurger={hasBurger}
            leftAction={headerLeftAction}
            hasAddNew={hasAddNew}
            hasLogoLink={hasLogoLink}
            hasDivider={hasDivider}
            isBackRequired={isBackRequired}
            hasLeadingAction={hasLeadingAction}
            hasWhiteBg={hasWhiteBg}
            notificationContainer={headerNotificationContainer}
            leadingIcon={leadingIcon}
          >
            {customHeader}
          </Header>
        )}

        {hasSearch && (
          <SearchPanel hasFilters={hasFilters} dataStat={searchDataStat} />
        )}

        {hasCategories && <CatalogueScrollGrid />}

        {hasSubcategories && (
          <SubcategoriesScrollGrid subcategories={subcategories} />
        )}

        {children}
      </S.HomeViewTopControls>
    </>
  );
};
