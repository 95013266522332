import React from 'react';
import * as S from './HelperText.styles';

interface HelperTextProps {
  helperText: string;
  hasError?: boolean;
  hasBottomMargin?: boolean;
}

export const HelperText: React.FC<HelperTextProps> = ({
  helperText,
  hasError,
  hasBottomMargin = true,
}) => {
  return (
    <S.Text
      $variant="4"
      $hasError={hasError}
      $hasBottomMargin={hasBottomMargin}
      data-testid="helper-text"
    >
      {helperText}
    </S.Text>
  );
};
