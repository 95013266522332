import styled, { css, DefaultTheme } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import {
  CheckboxAppearance,
  CheckboxColor,
  CheckboxLabelPosition,
  CheckboxSize,
} from '@/components/UI/Checkbox/Checkbox.types';

function getCheckboxWrapperHeight(size: CheckboxSize) {
  switch (size) {
    case 'sm':
      return 50;
    case 'md':
      return 55;
    case 'lg':
      return 60;
    default:
      return 60;
  }
}

function getRadioColor(color: CheckboxColor, theme: DefaultTheme) {
  switch (color) {
    case 'secondary':
      return theme.palette.system.blue;
    default:
      return theme.palette.brand.main;
  }
}

export const Switch = styled.span<{
  $appearance: CheckboxAppearance;
  $isVisible: boolean;
}>`
  position: relative;
  display: ${({ $isVisible }) => ($isVisible ? 'inline-block' : 'none')};

  ${({ $appearance }) =>
    $appearance === 'switch'
      ? css`
          width: 50px;
          height: 30px;
        `
      : css`
          flex: 0 0 30px;
          width: 30px;
          height: 30px;
        `}
`;

export const Box = styled.input`
  display: none;
`;

export const Knob = styled.span<{ $appearance: CheckboxAppearance }>`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  cursor: pointer;

  ${({ $appearance }) =>
    $appearance === 'switch'
      ? css`
          top: 0;
          left: 0;
          background-color: rgba(120, 120, 128, 0.16);
          transition: background-color 0.3s;
        `
      : css`
          top: 5px;
          left: 5px;
          width: 20px;
          height: 20px;
          border: 1px solid ${({ theme }) => theme.palette.primary.border};
          transition: border-color 0.3s;
        `}

  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    transition: transform 0.3s, background-color 0.3s;
    ${({ $appearance }) =>
      $appearance === 'switch'
        ? css`
            height: 26px;
            width: 26px;
            left: 2px;
            bottom: 2px;
            background-color: ${({ theme }) => theme.palette.system.white};
            box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
          `
        : css`
            width: 12px;
            height: 12px;
            top: 3px;
            left: 3px;
          `};
  }
`;

export const Wrapper = styled.div<{
  $hasBorderBottom?: boolean;
  $appearance: CheckboxAppearance;
  $size: CheckboxSize;
  $labelPosition: CheckboxLabelPosition;
  $color: CheckboxColor;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  height: ${({ $size }) => `${getCheckboxWrapperHeight($size)}px`};

  ${({ $labelPosition }) =>
    $labelPosition === 'left'
      ? css`
          justify-content: space-between;
        `
      : css`
          flex-direction: row-reverse;
          justify-content: flex-end;
        `}

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
  }

  ${({ $appearance, $color }) =>
    $appearance === 'switch'
      ? css`
          input:checked + ${Knob} {
            background-color: ${({ theme }) => theme.palette.system.green};
          }

          input:checked + ${Knob}:before {
            transform: translateX(20px);
          }
        `
      : css`
          input:checked + ${Knob} {
            border: 1px solid ${({ theme }) => getRadioColor($color, theme)};
          }
          input:checked + ${Knob}:before {
            background-color: ${({ theme }) => getRadioColor($color, theme)};
          }
        `}
`;

export const LabelBox = styled.label`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 10px;
  overflow: hidden;
`;

export const Label = styled(Typography.Body)`
  color: ${({ theme }) => theme.palette.primary.labels};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HelperText = styled(Typography.Body)<{
  $hasError?: boolean;
}>`
  margin: 5px 0;
  color: ${({ theme, $hasError }) =>
    $hasError ? theme.palette.system.red : theme.palette.primary.labels};
`;
