import { BaseCategory } from '@/api/types/categories.types';

export const groupCategoriesByParentName = (categories: BaseCategory[]) =>
  categories.reduce((groupedCategories, category) => {
    const parentName = category.parent?.name;
    if (!parentName) return groupedCategories;
    const currentGroup = groupedCategories.get(parentName);
    groupedCategories.set(
      parentName,
      currentGroup ? [...currentGroup, category] : [category]
    );
    return groupedCategories;
  }, new Map<string, BaseCategory[]>());

export function findSubcategoryByName(
  query: string,
  categories?: BaseCategory[]
): BaseCategory[] {
  if (!categories) return [];

  const matchingCategories: BaseCategory[] = [];

  function searchInCategory(category: BaseCategory) {
    if (category.name.toLowerCase().includes(query.toLowerCase())) {
      matchingCategories.push(category);
    }

    category.children?.forEach(searchInCategory);
  }

  categories.forEach(searchInCategory);

  return matchingCategories;
}

export function findAndGroupCategoriesByParentName(
  query: string,
  categories?: BaseCategory[]
) {
  const matchingCategories = findSubcategoryByName(query, categories);
  return groupCategoriesByParentName(matchingCategories);
}
