import styled, { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0% {
    opacity: 1;
  }
  
  50% {
    opacity: 0.4;
  }
  
  100% {
    opacity: 1;
  }
`;

export const Box = styled.div<{
  $borderRadius: number;
  $width: string;
  $height: string;
}>`
  border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  display: block;
  animation: ${waveAnimation} 2s ease-in-out 0.5s infinite;
  background-color: ${({ theme }) => theme.palette.secondary.background};
`;
