import styled from 'styled-components';
import { DRAWER_TRANSITION_TIME_MS } from '@/components/Drawer/Drawer.constants';

export const Overlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -80px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: ${DRAWER_TRANSITION_TIME_MS}ms ease-in-out;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  z-index: 10100;
`;
