import React, { useMemo, useState } from 'react';
import { DrawerItems } from '@/components/Drawer/Drawer.styles';
import { Drawer } from '@/components/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { Checkbox } from '@/components/UI/Checkbox/Checkbox';

const citiesMock = [
  { id: 1, label: 'Агдам' },
  { id: 2, label: 'Агдаш' },
  { id: 3, label: 'Баку' },
];

interface CitiesDrawerProps {
  isOpen: boolean;
  close: () => void;
  onSelect: (value: string) => void;
  selected: string;
}

export const CitiesDrawer: React.FC<CitiesDrawerProps> = ({
  isOpen,
  close,
  onSelect,
  selected,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const cities = useMemo(() => {
    return citiesMock.filter(c =>
      c.label.toLowerCase().includes(query.toLowerCase())
    );
  }, [query]);

  function onOptionClick(value: string) {
    onSelect(value);
    close();
  }

  return (
    <Drawer
      isOpen={isOpen}
      title={t('common.city')}
      left={{ icon: <AngleBackIcon />, onClick: close }}
      onSearch={setQuery}
    >
      {!!cities.length && (
        <DrawerItems>
          {cities.map(city => (
            <Checkbox
              onChangeHook={() => onOptionClick(city.label)}
              key={city.label}
              value={city.label === selected}
              label={city.label}
              appearance="radio"
              size="sm"
            />
          ))}
        </DrawerItems>
      )}
    </Drawer>
  );
};
