export function getImageSide(isCatalogueIcon: boolean, isStoresIcon?: boolean) {
  switch (true) {
    case isCatalogueIcon: {
      return 26;
    }
    case isStoresIcon: {
      return 60;
    }
    default: {
      return 50;
    }
  }
}
