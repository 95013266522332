import styled from 'styled-components';
import { CustomLink } from '@/components/CustomLink/CustomLink';

export const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 15px 9px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
  background-color: ${({ theme }) => theme.palette.tertiary.background};
  position: relative;
  box-sizing: border-box;
`;

export const BannerClose = styled.button`
  width: 42px;
  height: 100%;
  font-size: 0;
  background-image: url('/images/app_banners/app-banner-close.svg');
  background-position: center 22px;
  background-size: 10px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  border: 0;
  background-color: transparent;
`;

export const BannerText = styled.div`
  color: ${({ theme }) => theme.palette.primary.labels};
  font-size: 13px;
  font-weight: 400;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 320px) {
    font-size: 10px;
  }
`;

export const BannerDescription = styled.div`
  color: ${({ theme }) => theme.palette.secondary.labels};
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
`;

export const BannerLink = styled(CustomLink)`
  border-radius: 7px;
  background: ${({ theme }) => theme.palette.system.green};
  padding: 2px 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
`;
