import { useEffect } from 'react';

export const useBodyOverflow = (isOpen?: boolean) => {
  useEffect(() => {
    document.body.style.position = isOpen ? 'fixed' : '';
    document.body.style.width = isOpen ? '100%' : '';
  }, [isOpen]);

  return null;
};
