import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { useGetUrlAndHashFromPath } from '@/hooks/useGetUrlAndHashFromPath';
import { ROUTES } from '@/constants/routes';
import { QUERY } from '@/constants/common';
import { useSaveScrollPositionActions } from '@/components/ScrollSaver/ScrollSaver.hooks';

export function useSearch() {
  const customPush = useCustomPush();
  const { url } = useGetUrlAndHashFromPath();
  const saveScrollPositionActions = useSaveScrollPositionActions();

  function search(query: string) {
    saveScrollPositionActions();

    customPush({
      pathname: url.includes(ROUTES.ad) ? url : ROUTES.ad,
      query: {
        [`${QUERY.q}[${QUERY.keywords}]`]: query,
        button: '',
        mobile_site: true,
      },
    });
  }

  return search;
}
