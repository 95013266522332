import { useQueryParam } from '@/hooks/useQueryParam';
import { QUERY } from '@/constants/common';
import { useBannerStore } from '@/store/useBannerStore/useBannerStore';
import { BannerSource } from '@/store/useBannerStore/useBannerStore.types';
import { useEffect } from 'react';

export function useAdManagerBannerVisible() {
  const [digitalSnail] = useQueryParam(QUERY.digitalSnail);
  const [setBannerTopSource] = useBannerStore(state => [
    state.setBannerTopSource,
  ]);

  useEffect(() => {
    if (digitalSnail) {
      setBannerTopSource(BannerSource.Admanager);
    }
  }, [setBannerTopSource, digitalSnail]);

  return digitalSnail === 'true';
}
