import React, { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import * as S from '@/components/Header/Header.styles';
import { Button } from '@/components/UI/Button/Button';
import { AngleBackIcon, PlusIcon } from '@/components/UI/Icons/Icons';
import { BurgerNav } from '@/components/BurgerNav/BurgerNav';
import { LogoLink } from '@/components/LogoLink/LogoLink';
import { useIsRouterFirstLoad } from '@/hooks/useIsFirstLoadBasedOnRouter';
import { ROUTES } from '@/constants/routes';

interface HeaderProps {
  title?: string;
  hasBurger?: boolean;
  leftAction?: React.ReactNode;
  hasLogoLink?: boolean;
  hasAddNew?: boolean;
  hasDivider?: boolean;
  isBackRequired?: boolean;
  hasLeadingAction?: boolean;
  hasWhiteBg?: boolean;
  notificationContainer?: React.ReactNode;
  leadingIcon?: React.ReactNode;
}

export const Header: React.FC<PropsWithChildren<HeaderProps>> = ({
  children,
  title,
  hasBurger,
  leftAction,
  hasLogoLink,
  hasAddNew,
  hasDivider = true,
  hasLeadingAction = true,
  hasWhiteBg = true,
  isBackRequired,
  notificationContainer,
  leadingIcon,
}) => {
  const router = useRouter();
  const isFirstLoad = useIsRouterFirstLoad();

  return (
    <>
      {notificationContainer}
      <S.HeaderBox
        $hasDivider={hasDivider}
        $hasCustomHeader={!!children}
        $hasLeadingAction={hasLeadingAction}
        $hasWhiteBg={hasWhiteBg}
      >
        {hasLeadingAction &&
          (isBackRequired || (!isFirstLoad && !hasBurger) ? (
            <Button
              appearance="transparent"
              hasPadding={false}
              onClick={router.back}
            >
              {leadingIcon || <AngleBackIcon />}
            </Button>
          ) : (
            <BurgerNav />
          ))}

        {children || (
          <>
            {hasLogoLink ? (
              <LogoLink />
            ) : (
              <S.HeaderTitle $variant="3">{title}</S.HeaderTitle>
            )}

            {hasAddNew ? (
              <S.NewAdLink href={ROUTES.newAd}>
                <PlusIcon />
              </S.NewAdLink>
            ) : (
              leftAction
            )}
          </>
        )}
      </S.HeaderBox>
    </>
  );
};
