import { create } from 'zustand';

interface SearchStore {
  isSuggestionsOpened: boolean;
  setIsSuggestionsOpened: (value: boolean) => void;
}

export const useSearchStore = create<SearchStore>(set => ({
  isSuggestionsOpened: false,
  setIsSuggestionsOpened: value => set({ isSuggestionsOpened: value }),
}));
