import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { SkeletonLoader } from '@/components/SkeletonLoader/SkeletonLoader';

function getBaseImageBoxStyles(isHorizontalView?: boolean) {
  return `
    width: ${isHorizontalView ? '60px' : '40px'};
    height: ${isHorizontalView ? '60px' : '40px'};
    border-radius: ${isHorizontalView ? '16px' : '7px'};

    ${
      isHorizontalView
        ? `
          margin-bottom: 5px;
        `
        : `
          padding: 2px;
        `
    }
  `;
}

export const ImageBox = styled.div<{
  $isHorizontalView?: boolean;
}>`
  ${({ $isHorizontalView }) => getBaseImageBoxStyles($isHorizontalView)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.background};

  img {
    object-fit: contain;
  }
`;
export const Texts = styled.div`
  overflow: hidden;
`;

export const GridItemTitle = styled(Typography.Caption)`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.labels};
`;

export const ItemTitle = styled(Typography.Body)`
  margin-bottom: 3px;
`;

export const ItemDescription = styled(Typography.Caption)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;

export const ImageSkeletonLoader = styled(SkeletonLoader)<{
  $isHorizontalView?: boolean;
}>`
  ${({ $isHorizontalView }) => getBaseImageBoxStyles($isHorizontalView)};
`;

export const Box = styled.div<{
  $isHorizontalView?: boolean;
  $isLoading?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ $isHorizontalView }) =>
    $isHorizontalView
      ? css`
          min-width: 80px;
        `
      : css`
          display: grid;
          grid-template-columns: 40px auto;
          gap: 15px;
          overflow: hidden;
        `}

  ${({ $isLoading }) =>
    $isLoading
      ? css`
          ${ImageBox} {
            display: none;
          }
        `
      : css`
          ${ImageSkeletonLoader} {
            display: none;
          }
        `}
`;
