import styled, { css } from 'styled-components';
import { PageTopControlsPosition } from '@/components/PageTopControls/PageTopControls.types';

export const HomeViewTopControls = styled.div<{
  $isVisible: boolean;
  $topControlsHeight: number;
  $hasBorderBottom?: boolean;
  $position: PageTopControlsPosition;
}>`
  background-color: ${({ theme }) => theme.palette.primary.background};
  position: ${({ $position }) => $position};

  ${({ $hasBorderBottom }) =>
    $hasBorderBottom &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
    `}

  ${({ $isVisible, $position, $topControlsHeight }) =>
    $position === 'fixed' &&
    css`
      z-index: 10020;
      left: 0;
      right: 0;
      top: -${$topControlsHeight}px;
      transition: transform 0.3s;
      ${() =>
        $isVisible &&
        css`
          transform: translateY(100%);
        `}
    `}
`;

export const HomeViewTopControlsGutter = styled.div<{
  $topControlsHeight: number;
}>`
  height: ${({ $topControlsHeight }) => `${$topControlsHeight}px`};
`;
