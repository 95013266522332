import { useRouter } from 'next/router';
import { LinkProps } from 'next/link';
import { isProduction } from '@/utils/helpers';
import { checkRoute } from '@/components/CustomLink/CustomLink.helpers';

export function useCustomPush() {
  const router = useRouter();

  return function customPush(href: LinkProps['href']) {
    const { isInternal, hrefString } = checkRoute(href);

    if (!isProduction()) {
      return router.push(href);
    }

    if (isInternal) {
      router.push(href);
    } else {
      window.location.href = hrefString;
    }
  };
}
