import Link, { LinkProps } from 'next/link';
import React, { ComponentProps, forwardRef, PropsWithChildren } from 'react';
import { isProduction } from '@/utils/helpers';
import { checkRoute } from '@/components/CustomLink/CustomLink.helpers';

// This component should be removed after full migration to the new FE

type Props = PropsWithChildren &
  (ComponentProps<'a'> | LinkProps) & {
    href: LinkProps['href'];
    dataTestId?: string;
  };

export const CustomLink = forwardRef<HTMLAnchorElement, Props>(
  ({ href, children, dataTestId, ...props }, ref) => {
    const { isInternal, hrefString } = checkRoute(href);

    if (!isProduction() || isInternal) {
      return (
        <Link
          {...props}
          ref={ref}
          data-component="Link"
          data-testid={dataTestId ?? 'custom-link-link'}
          href={href}
        >
          {children}
        </Link>
      );
    }

    function onATagClick(e: React.MouseEvent<HTMLAnchorElement>) {
      props.onClick?.(e);
    }

    return (
      <a
        {...props}
        onClick={onATagClick}
        ref={ref}
        data-component="a"
        data-testid={dataTestId ?? 'custom-link-a'}
        href={hrefString}
      >
        {children}
      </a>
    );
  }
);

CustomLink.displayName = 'CustomLink';
