import { useState, useEffect, useRef } from 'react';
import { TOP_BANNER_HEIGHT } from '@/components/Banners/Banners.constants';
import { useIsTopBannerVisible } from '@/hooks/useIsTopBannerVisible';

const WAIT_TIME = 1000;

export const useScrollVisibility = () => {
  const isTopBannerVisible = useIsTopBannerVisible();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  const [pageHeight, setPageHeight] = useState(0);

  const timeRef = useRef(0);

  useEffect(() => {
    const height = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (pageHeight !== height) {
      setPageHeight(height);
    }
  }, [pageHeight]);

  useEffect(() => {
    let animationFrameId: number;

    const handleScroll = (e: Event) => {
      e.preventDefault();

      animationFrameId = requestAnimationFrame(time => {
        const currentScrollPos = window.scrollY;
        const topOfPage = isTopBannerVisible ? TOP_BANNER_HEIGHT : 0;
        const deltaTime = time - timeRef.current;
        const isScrollToTop = prevScrollPos > currentScrollPos;

        if (document.body.style.position !== 'fixed') {
          setIsEndOfPage(
            currentScrollPos === document.body.scrollHeight - window.innerHeight
          );

          if (deltaTime > WAIT_TIME) {
            setIsVisible(isScrollToTop && currentScrollPos >= topOfPage);
            timeRef.current = time;
          }

          setIsTopOfPage(currentScrollPos <= topOfPage);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, pageHeight]);

  useEffect(() => {
    let animationFrameId: number;

    const handleScroll = () => {
      animationFrameId = requestAnimationFrame(() => {
        setPrevScrollPos(window.scrollY);
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    isVisible,
    isEndOfPage,
    isTopOfPage,
    scrollPos: prevScrollPos,
  };
};
