import styled from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';

export const Text = styled(Typography.Body)<{
  $hasError?: boolean;
  $hasBottomMargin?: boolean;
}>`
  margin: ${({ $hasBottomMargin }) => ($hasBottomMargin ? '5px 0' : '5px 0 0')};
  color: ${({ theme, $hasError }) =>
    $hasError ? theme.palette.system.red : theme.palette.primary.labels};
`;
